<template>
  <v-dialog v-model="dialog" fullscreen transition="none">
    <v-img class="d-flex justify-center" :src="require('@/assets/img/login-background.png')"
      style="position: fixed; top: 0; left: 0; width: 100vw; height: 100vh;"></v-img>
    <v-card class="elevation-0" :class="!isMobile ? 'transparent' : 'white'">
      <v-toolbar flat color="transparent" style="position: sticky; top: 0;"></v-toolbar>
      <v-card class="elevation-0 elevation-md-2 px-8 mb-md-15 pb-6"
        :class="!$vuetify.breakpoint.mdAndDown && 'rounded-xl'" style="max-width: 600px; margin: auto;">
        <v-card-title class="mt-1 d-flex justify-center align-center">
          <v-img max-height="30" contain class="ml-10 mt-5" :src="require('@/assets/logo-teal.png')"></v-img>
        </v-card-title>
        <v-card-text class="blue-grey--text text--darken-3 pb-1">
          <h3 class="text-h5 font-weight-bold mt-6">{{ title }}</h3>
        </v-card-text>

        <!-- Mensaje de carga -->
        <v-card-text v-if="loading" class="blue-grey--text text--darken-3">
          <p class="text-body-2">Estamos validando tu suscripción, por favor espera...</p>
        </v-card-text>

        <!-- Mensaje de éxito -->
        <v-card-text v-else-if="status === 'success' || status === 'update-success'"
          class="blue-grey--text text--darken-3">
          <p class="text-body-2">¡Enhorabuena! Tu suscripción al plan {{ plan }} se ha activado correctamente.</p>
          <v-btn block class="rounded-lg py-5 font-weight-bold mt-6" color="primary" @click="goToApp">
            Ir a la aplicación
          </v-btn>
        </v-card-text>

        <!-- Mensaje de cancelación -->
        <v-card-text v-else-if="status === 'cancel'" class="blue-grey--text text--darken-3">
          <p class="text-body-2">La suscripción fue cancelada o el pago no se completó.</p>
          <p class="text-body-2">Si quieres volver a intentarlo, regresa a la página de planes.</p>
          <v-btn block class="mt-2 rounded-lg py-4 font-weight-bold text-none" color="primary" @click="goToPlans">
            Volver a planes
          </v-btn>
        </v-card-text>

        <!-- Mensaje de suscripción activa -->
        <v-card-text v-else-if="status === 'active'" class="blue-grey--text text--darken-3">
          <p class="text-body-2">Ya tienes una suscripción activa al plan {{ plan }}.</p>
          <p class="text-body-2">Si quieres cambiar de plan, regresa a la página de planes.</p>
          <v-btn block class="mt-2 rounded-lg py-4 font-weight-bold text-none" color="primary" @click="goToApp">
            Ir a la aplicación
          </v-btn>
        </v-card-text>

        <!-- Mensaje de error -->
        <v-card-text v-else class="blue-grey--text text--darken-3">
          <p class="text-body-2">Se ha producido un error al intentar validar la suscripción.</p>
          <p>Por favor, inténtalo de nuevo más tarde o contacta con soporte.</p>
          <v-btn block class="mt-2 rounded-lg py-4 font-weight-bold text-none" color="primary" @click="goToPlans">
            Volver a planes
          </v-btn>
        </v-card-text>
      </v-card>
      <div class="d-flex align-center justify-center gray--text text-darken-4 mt-n8">
        <v-btn text plain class="text-capitalize" :href="LANDING_URL" target="_blank">© Slotspot</v-btn>
        <span>·</span>
        <v-btn text plain class="text-capitalize" :href="`${LANDING_URL}/contact`" target="_blank">Contacto</v-btn>
        <span>·</span>
        <v-btn text plain class="text-none" :href="`${LANDING_URL}/terms`" target="_blank">Términos y
          condiciones</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SubscriptionResult',
  data() {
    return {
      LANDING_URL: process.env.VUE_APP_LANDING_URL || 'https://slotspot.app',
      dialog: true,
      status: null,
      businessId: null,
      plan: null,
      sessionId: null,
      loading: true,
      title: 'Procesando suscripción...'
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth < 600
    }
  },
  async created() {
    // Lee la query: ?status=success&businessId=...&plan=...&session_id=...
    const { status, planId, session_id } = this.$route.query
    const { businessId } = this.$route.params

    this.status = status
    this.businessId = businessId
    this.planId = planId
    this.sessionId = session_id

    if (this.status === 'success') {
      this.title = 'Suscripción completada'
      // Llamar al backend para confirmar la suscripción
      if (this.sessionId) {
        try {
          const response = await fetch(`${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}/api/v1/businesses/${this.businessId}/subscriptions/checkout-success?session_id=${this.sessionId}&planId=${this.planId}`)
          const data = await response.json()
          if (response.ok && data.status !== 'cancel') {
            // Suscripción confirmada
            this.status = 'success'
          } else {
            this.status = 'error'
            this.title = 'Error en la suscripción'
          }
        } catch (error) {
          console.error("Error al verificar suscripción:", error)
          this.status = 'error'
          this.title = 'Error en la suscripción'
        }
      } else {
        this.status = 'error'
      }
    } else if (this.status === 'cancel') {
      this.title = 'Suscripción cancelada'
      // Opcionalmente podrías llamar al backend en /checkout-canceled para logs, pero no es necesario
    } else if (this.status === 'active') {
      this.title = 'Ya tienes una suscripción activa'
      // Opcionalmente podrías llamar al backend en /checkout-canceled para logs, pero no es necesario
    } else if (this.status === 'update-success') {
      this.title = 'Suscripción completada'
      // Actulizar navegador con el nuevo plan
      if (!this.$route.query.reloaded) {
        // Agregar query param 'reloaded' antes de recargar la página
        const url = new URL(window.location.href)
        url.searchParams.append('reloaded', 'true')
        window.history.replaceState({}, document.title, url)
        window.location.reload()
      }
    } else {
      this.title = 'Error en la suscripción'
      this.status = 'error'
    }

    this.loading = false
  },
  methods: {
    goToApp() {
      // Redirige a la aplicación (dashboard o página principal)
      this.$router.push('/businesses/' + this.businessId)
    },
    goToPlans() {
      // Redirige a la página de planes
      this.$router.push(`/businesses/${this.businessId}/prices?backPath=subscription-result`)
    }
  }
}
</script>

<style scoped>
p a {
  color: #00bfa5 !important;
  text-decoration: none;
}

p a:hover {
  color: #444 !important;
}

p .action {
  color: #00bfa5 !important;
  cursor: pointer;
  font-weight: bold;
}

p .action:hover {
  color: #444 !important;
}

a {
  font-weight: bold;
  letter-spacing: 0.1px;
}
</style>