import Vue from 'vue'
import Router from 'vue-router'

// Public
import PublicLayout from '@/layouts/public/PublicLayout'
import PublicLoginView from '@/views/public/LoginView'
import PublicVerifyEmailView from '@/views/public/VerifyEmailView'
import PublicResetView from '@/views/public/ResetView'

// Business
import BusinessLayout from '@/layouts/business/MainLayout'
import SetupWizardView from '@/views/business/SetupWizardView.vue'
import SubscriptionResultView from '@/views/business/SubscriptionResultView'
import PricesView from '@/views/business/PricesView'
import PurchaseCreditView from '@/views/business/PurchaseCreditView'
import PurchaseCreditResultView from '@/views/business/PurchaseCreditResultView.vue'

// Calendar
import CalendarLayout from '@/layouts/business/CalendarLayout'
import CalendarView from '@/views/business/calendar/CalendarView'

// Clients
import ClientsLayout from '@/layouts/business/ClientsLayout'
import ClientsListView from '@/views/business/clients/ListView'
import ClientsDetailsView from '@/views/business/clients/DetailsView'
import ClientsFormView from '@/views/business/clients/FormView'

// Profile
import ProfileLayout from '@/layouts/business/MarketingLayout'
import ServicesFormView from '@/views/business/profile/ServicesFormView'
import EmployeesView from '@/views/business/profile/EmployeesView'
import EmployeesFormView from '@/views/business/profile/EmployeesFormView'
import LinksView from '@/views/business/profile/LinksView'
import DetailsView from '@/views/business/profile/DetailsView'
// import BillingView from '@/views/business/profile/BillingView'
import ServicesView from '@/views/business/profile/ServicesView'

// Settings
import SettingsLayout from '@/layouts/business/BusinessLayout'
import BookingRulesView from '@/views/business/settings/BookingRulesView'
import VacationsView from '@/views/business/settings/VacationsView'
import VacationsFormView from '@/views/business/settings/VacationsFormView'
import NotificationsView from '@/views/business/settings/NotificationsView'
// import PoliciesView from '@/views/business/marketing/PoliciesView'

// Activity
import ActivityLayout from '@/layouts/business/ActivityLayout'
import ActivityView from '@/views/business/activity/ActivityView'


import { isFeatureAvailable } from '@/utils'
import { FEATURES } from '@/consts'
import store from '../store'

// Discount
import DiscountView from '@/views/public/DiscountView'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/discount/:code',
            name: 'DiscountView',
            component: DiscountView,
            props: true
        },
        {
            path: '/',
            redirect: '/login',
            component: PublicLayout,
            children: [
                {
                    path: '/login',
                    name: 'LoginView',
                    component: PublicLoginView,
                },
                {
                    path: '/signup',
                    name: 'SignupView',
                    component: PublicLoginView,
                    props: () => ({ isSignUpProp: true })
                },
                {
                    path: '/verify-email/:token',
                    name: 'PublicVerifyEmailView',
                    component: PublicVerifyEmailView,
                    props: true
                },
                {
                    path: '/reset',
                    name: 'PublicResetView',
                    component: PublicResetView,
                }
            ]
        },
        {
            path: '/businesses/:businessId',
            name: 'business',
            redirect: '/businesses/:businessId/calendar',
            component: BusinessLayout,
            beforeEnter: (to, from, next) => {
                const { firstTime } = to.query;
                if (firstTime && to.name !== 'wizard') {
                    const businessId = to.params?.businessId || store.getters.business?.id || localStorage.getItem('businessId');
                    if (!businessId) {
                        return next({ name: 'LoginView' });
                    }
                    next({
                        name: 'wizard',
                        query: { ...to.query },
                        params: { businessId }
                    });
                } else {
                    next();
                }
            },
            props: true,
            children: [
                {
                    name: 'wizard',
                    path: 'wizard',
                    component: SetupWizardView,
                },
                {
                    path: 'activity',
                    component: ActivityLayout,
                    beforeEnter: async (to, from, next) => {
                        if (!store.getters.business?.id) {
                            await store.dispatch('fetchBusiness', to.params.businessId);
                        }
                        if (isFeatureAvailable(FEATURES.BusinessAnalytics)) {
                            console.log('BusinessAnalytics is available');
                            next();
                        } else {
                            console.log('BusinessAnalytics is not available');
                            next(from.fullPath);
                        }
                    },
                    children: [
                        {
                            path: '',
                            component: ActivityView
                        },
                    ]
                },
                {
                    name: 'calendar',
                    path: 'calendar',
                    component: CalendarLayout,
                    children: [
                        {
                            path: '',
                            component: CalendarView
                        },
                    ]
                },
                {
                    path: 'clients',
                    component: ClientsLayout,
                    children: [
                        {
                            path: '',
                            component: ClientsListView
                        },
                        {
                            path: 'details/:clientId',
                            component: ClientsDetailsView,
                            props: true
                        },
                        {
                            path: 'form/:clientId',
                            component: ClientsFormView,
                            props: true
                        },
                    ]
                },
                {
                    path: 'profile',
                    component: ProfileLayout,
                    redirect: 'profile/links',
                    children: [
                        {
                            path: 'links',
                            component: LinksView,
                        },
                        {
                            path: 'details',
                            component: DetailsView,
                        },
                        {
                            path: 'services',
                            component: ServicesView,
                        },
                        {
                            path: 'services/:serviceId',
                            component: ServicesFormView,
                            props: true
                        },
                        {
                            path: 'employees',
                            component: EmployeesView,
                        },
                        {
                            path: 'employees/:employeeId',
                            component: EmployeesFormView,
                            props: true
                        },
                    ]
                },
                {
                    path: 'settings',
                    component: SettingsLayout,
                    redirect: 'settings/rules',
                    children: [
                        {
                            path: 'rules',
                            component: BookingRulesView,
                        },
                        {
                            path: 'notifications',
                            component: NotificationsView,
                        },
                        {
                            path: 'vacations',
                            component: VacationsView,
                            beforeEnter: async (to, from, next) => {
                                if (!store.getters.business?.id) {
                                    await store.dispatch('fetchBusiness', to.params.businessId);
                                }
                                if (isFeatureAvailable(FEATURES.Vacations)) {
                                    console.log('BusinessAnalytics is available');
                                    next();
                                } else {
                                    console.log('BusinessAnalytics is not available');
                                    next(from.fullPath);
                                }
                            },
                        },
                        {
                            path: 'vacations/:vacationId',
                            component: VacationsFormView,
                            props: true
                        },
                    ]
                },
                {
                    path: 'prices',
                    component: PricesView,
                },
                {
                    path: 'subscription-result',
                    component: SubscriptionResultView,
                },
                {
                    path: 'purchase-credit',
                    component: PurchaseCreditView,
                },
                {
                    path: 'purchase-credit-result',
                    component: PurchaseCreditResultView,
                },
            ]
        },
        // For 404 page
        {
            path: '/:pathMatch(.*)*',
            beforeEnter: () => {
                window.location.href = `${process.env.VUE_APP_LANDING_URL}/404`;
            },
        }
    ],
    mode: 'history',
})
