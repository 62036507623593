<!-- TODO: No añadir botón de añadir categoría -->
<template>
    <div>
        <v-toolbar flat :height="$vuetify.breakpoint.mdAndUp ? 150 : 180" class="px-lg-4 mb-md-6 mb-lg-0 mb-0">
            <v-row>
                <v-col cols="12" md="9" class="d-flex flex-column mt-md-3">
                    <h2>Notificaciones</h2>
                    <span class="font-weight-regular grey--text text--darken-2">
                        Mantén tus reservas en la mente de tus clientes enviando notificaciones de recordatorio y
                        seguimientos mediante email, SMS y Whatsapp.
                    </span>
                </v-col>
                <v-col cols="12" md="3" class="flex-column py-6 py-md-0 d-none d-md-flex"
                    v-if="notificationMethodsAvailables.includes(notificationMethods.WHATSAPP) || notificationMethodsAvailables.includes(notificationMethods.SMS)">
                    <CardPurchaseCredits />
                </v-col>
            </v-row>
        </v-toolbar>
        <v-container fluid class="pa-lg-8">
            <v-col cols="12" class="flex-column pa-0 d-flex d-md-none"
                v-if="notificationMethodsAvailables.includes(notificationMethods.WHATSAPP) || notificationMethodsAvailables.includes(notificationMethods.SMS)">
                <CardPurchaseCredits class="pb-3" />
            </v-col>
            <v-card v-for="(notificationType, notificationTypesIdx) in notificationTypes"
                :key="`notification-${notificationTypesIdx}`" outlined class="rounded-0"
                :class="notificationTypesIdx == 0 ? 'rounded-t-lg' : notificationTypesIdx == notificationTypes.length - 1 ? 'rounded-b-lg' : ''"
                :style="notificationTypesIdx > 0 ? 'border-top: 0;' : ''">
                <v-card-text class="pa-6 black--text d-flex flex-row">
                    <v-row>
                        <v-col cols="12" lg="9" class="d-flex flex-column justify-center">
                            <span class="body-1 font-weight-bold primary--text">
                                <v-icon left color="primary">{{ notificationType.icon }}</v-icon>
                                {{ notificationType.name }}
                                <span class="ml-2 body-2 font-weight-regular black--text">({{
                                    notificationResult(notificationType) }})</span>
                            </span>
                            <span class="body-1 mt-1">
                                <span class="ml-9"></span>
                                {{ notificationType.description }}
                            </span>
                        </v-col>

                        <v-col cols="12" lg="3">
                            <BlockingBadge :locked="!notificationTypesAvailables.includes(notificationType.value)" blur
                                style="width: 100%" class="d-flex justify-end align-center">
                                <v-row>
                                    <v-col cols="12" class="d-flex justify-end align-center">
                                        <BlockingBadge
                                            :locked="!isNotificationAvailable(notificationType, notificationMethods.EMAIL) && notificationTypesAvailables.includes(notificationType.value)">
                                            <v-chip
                                                :color="notificationConfig[`${notificationType.value}Email`] && isNotificationAvailable(notificationType, notificationMethods.EMAIL) ? 'primary' : 'grey'"
                                                label class="px-5 rounded-lg"
                                                :disabled="!isNotificationAvailable(notificationType, notificationMethods.EMAIL) || !notificationTypesAvailables.includes(notificationType.value)"
                                                :outlined="!notificationConfig[`${notificationType.value}Email`] && isNotificationAvailable(notificationType, notificationMethods.EMAIL)"
                                                @click="notificationConfig[`${notificationType.value}Email`] = !notificationConfig[`${notificationType.value}Email`]">
                                                <v-icon small left>
                                                    mdi-email
                                                </v-icon>
                                                Email
                                            </v-chip>
                                        </BlockingBadge>
                                        <BlockingBadge
                                            :locked="!isNotificationAvailable(notificationType, notificationMethods.SMS) && notificationTypesAvailables.includes(notificationType.value)">
                                            <v-chip
                                                :color="notificationConfig[`${notificationType.value}Sms`] && isNotificationAvailable(notificationType, notificationMethods.SMS) ? 'primary' : 'grey'"
                                                label class="px-5 rounded-lg ml-2"
                                                :disabled="!isNotificationAvailable(notificationType, notificationMethods.SMS)"
                                                :outlined="!notificationConfig[`${notificationType.value}Sms`] && isNotificationAvailable(notificationType, notificationMethods.SMS)"
                                                @click="notificationConfig[`${notificationType.value}Sms`] = !notificationConfig[`${notificationType.value}Sms`]">
                                                <v-icon small left>
                                                    mdi-message
                                                </v-icon>
                                                SMS
                                            </v-chip>
                                        </BlockingBadge>
                                        <BlockingBadge
                                            :locked="!isNotificationAvailable(notificationType, notificationMethods.WHATSAPP) && notificationTypesAvailables.includes(notificationType.value)">
                                            <v-chip
                                                :color="notificationConfig[`${notificationType.value}Whatsapp`] && isNotificationAvailable(notificationType, notificationMethods.WHATSAPP) ? 'primary' : 'grey'"
                                                label class="px-5 rounded-lg ml-2"
                                                :disabled="!isNotificationAvailable(notificationType, notificationMethods.WHATSAPP)"
                                                :outlined="!notificationConfig[`${notificationType.value}Whatsapp`] && isNotificationAvailable(notificationType, notificationMethods.WHATSAPP)"
                                                @click="notificationConfig[`${notificationType.value}Whatsapp`] = !notificationConfig[`${notificationType.value}Whatsapp`]">
                                                <v-icon small left>
                                                    mdi-whatsapp
                                                </v-icon>
                                                Whatsapp
                                            </v-chip>
                                        </BlockingBadge>
                                    </v-col>
                                </v-row>
                            </BlockingBadge>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <div class="d-flex flex-row justify-end align-center pt-1 pb-5 py-lg-1">
                <v-btn text :disabled="!hasChanged">Descartar</v-btn>
                <v-btn color="primary" class="py-5 px-6 rounded-lg ml-2" small :disabled="!hasChanged" @click="save">
                    <v-icon left>mdi-content-save</v-icon>
                    Guardar
                </v-btn>
            </div>
        </v-container>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getFeatureValue } from '@/utils';
import { BOOKING_NOTIFICATION_TYPE, FEATURES, NOTIFICATION_METHODS } from '@/consts';

import BlockingBadge from '@/components/business/BlockingBadge.vue';
import CardPurchaseCredits from '@/components/business/CardPurchaseCredits.vue';

export default {
    name: 'NotificationsView',
    components: {
        BlockingBadge,
        CardPurchaseCredits
    },
    data() {
        return {
            filter: '',
            notificationConfig: {},
            defaultNotificationConfig: "",
            hasChanged: false,
            notificationMethods: NOTIFICATION_METHODS
        }
    },
    // TODO: Obtener notificaciones de la base de datos
    computed: {
        serializedNotificationConfig() {
            return JSON.stringify(this.notificationConfig)
        },
        notificationTypes() {
            const allNotifications = [
                {
                    name: 'Reserva pendiente',
                    description: 'Envía una notificación de cita pendiente a los clientes que tienen una cita pendiente de confirmar por ti.',
                    value: BOOKING_NOTIFICATION_TYPE.PENDING,
                    icon: 'mdi-calendar-clock',
                },
                {
                    name: 'Reserva confirmada',
                    description: 'Envía un notificación de cita confirmada a los clientes que han confirmado su cita.',
                    value: BOOKING_NOTIFICATION_TYPE.CONFIRMED,
                    icon: 'mdi-check',
                },
                {
                    name: 'Reserva cancelada',
                    description: 'Envía un notificación de cita cancelada a los clientes que han cancelado su cita.',
                    value: BOOKING_NOTIFICATION_TYPE.CANCELLED,
                    icon: 'mdi-account-off',
                },
                {
                    name: 'Reserva rechazada',
                    description: 'Envía un notificación de cita rechazada a los clientes cuya cita ha sido rechazada.',
                    value: BOOKING_NOTIFICATION_TYPE.REJECTED,
                    icon: 'mdi-account-remove',
                },
                {
                    name: 'Reserva reprogramada',
                    description: 'Envía un notificación de cita reprogramada a los clientes cuya cita ha sido reprogramada.',
                    value: BOOKING_NOTIFICATION_TYPE.RESCHEDULED,
                    icon: 'mdi-calendar-sync',
                },
                {
                    name: 'Recordatorio de cita',
                    description: 'Envía un recordatorio de cita a los clientes que tienen una cita en las próximas horas (ver Ajuste de reservas).',
                    value: BOOKING_NOTIFICATION_TYPE.REMINDER,
                    icon: 'mdi-calendar-start',
                },
                {
                    name: 'Recordatorio de reseña',
                    description: 'Envía un recordatorio de reseña de Google a los clientes que han tenido una cita recientemente.',
                    value: BOOKING_NOTIFICATION_TYPE.REVIEW,
                    icon: 'mdi-star',
                },
            ]

            return allNotifications
        },
        notificationMethodsAvailables() {
            return getFeatureValue(FEATURES.NotificationMethodsAvailable)
        },
        notificationTypesAvailables() {
            return getFeatureValue(FEATURES.NotificationTypesAvailable)
        }
    },
    watch: {
        notificationConfig: {
            handler: function () {
                this.hasChanged = this.serializedNotificationConfig !== this.defaultNotificationConfig && this.defaultNotificationConfig !== ''
            },
            deep: true
        }
    },
    async created() {
        if (Object.keys(this.notificationConfig).length === 0) {
            this.notificationConfig = await this.fetchNotificationConfig();
            this.defaultNotificationConfig = JSON.stringify(this.notificationConfig);
        }
        console.log(this.notificationConfig);
    },
    mounted() {

    },
    methods: {
        ...mapActions(['fetchNotificationConfig', 'updateNotificationConfig']),
        notificationResult(notificationType) {
            const isSms = this.notificationConfig[`${notificationType.value}Sms`];
            const isEmail = this.notificationConfig[`${notificationType.value}Email`];
            const isWhatsapp = this.notificationConfig[`${notificationType.value}Whatsapp`]
            if (isSms && isEmail && isWhatsapp) return 'Whatsapp, SMS y Email';
            if (isSms && isEmail) return 'SMS y Email';
            if (isSms && isWhatsapp) return 'Whatsapp y SMS';
            if (isWhatsapp && isEmail) return 'Whatsapp y Email';
            if (isSms) return 'SMS';
            if (isEmail) return 'Email';
            if (isWhatsapp) return 'Whatsapp';
            return 'Sin notificar';
        },
        isNotificationAvailable(notificationType, notificationMethod) {
            return this.notificationTypesAvailables.includes(notificationType.value) && this.notificationMethodsAvailables.includes(notificationMethod)
        },
        async save() {
            await this.updateNotificationConfig(this.notificationConfig)
            this.hasChanged = false
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
