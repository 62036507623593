<template>
    <v-dialog v-model="resetEmailDialog" fullscreen transition="none">
        <v-img class="d-flex justify-center" :src="require('@/assets/img/login-background.png')"
            style="position: fixed; top: 0; left: 0; width: 100vw; height: 100vh;"></v-img>
        <v-card class="elevation-0" :class="!isMobile ? 'transparent' : 'white'">
            <v-toolbar flat color="transparent" style="position: sticky; top: 0;">
                <!-- <v-btn icon large @click="close">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn> -->
            </v-toolbar>
            <v-card class="elevation-0 elevation-md-2 px-8 mb-md-15 pb-6"
                :class="!$vuetify.breakpoint.mdAndDown && 'rounded-xl'" style="max-width: 600px; margin: auto;">
                <v-card-title class="mt-1 d-flex justify-center align-center">
                    <v-img max-height="30" contain class="ml-10 mt-5" :src="require('@/assets/logo-teal.png')"></v-img>
                </v-card-title>
                <v-card-text class="blue-grey--text text--darken-3 pb-1">
                    <h3 class="text-h5 font-weight-bold mt-6">{{ STEPS_TITLES[currentStep] }}</h3>
                </v-card-text>
                <v-card-text class=" blue-grey--text text--darken-3" v-if="currentStep === STEPS.CHECK_EMAIL">
                    <p class="text-body-2">¡Gracias! Si <strong>{{ email }}</strong> coincide con una dirección
                        de correo electrónico que tenemos registrada, entonces te hemos enviado un correo con
                        instrucciones adicionales para que puedas restablecer la contraseña.</p>
                    <p class="text-body-2 mt-6">Si no has recibido un correo electrónico pasados 5 minutos, revisa tu
                        carpeta de correo no deseado, <span class="action" @click="handleClickResendEmail">vuelve a
                            enviarlo</span> o <span class="action" @click="handleClickTryOtherEmail">prueba otra
                            dirección de correo electrónico</span>.</p>
                </v-card-text>
                <v-card-text class=" blue-grey--text text--darken-3" v-if="currentStep === STEPS.RECHECK_EMAIL">
                    <p class="text-body-2">Hemos reenviado las instrucciones para restablecer la contraseña a <strong>{{
                        email }}</strong> si este correo electrónico está registrado.</p>
                    <p class="text-body-2 mt-6">Vuelve a comprobarlo. Si sigues sin recibir el correo, <span
                            class="action" @click="handleClickTryOtherEmail">prueba otra
                            dirección de correo electrónico</span>.</p>
                </v-card-text>
                <v-card-text class=" blue-grey--text text--darken-3" v-if="currentStep === STEPS.SET_EMAIL">
                    <p class="text-body-2">Introduce la dirección de correo electrónico asociada a tu cuenta y te
                        enviaremos un vínculo para restablecer tu contraseña.</p>
                    <label class="font-weight-bold">Correo electrónico</label>
                    <v-text-field v-model="email" type="email" class="mt-2"
                        :hint="emailError ? 'El email debe ser válido' : ''" :error="emailError"
                        outlined></v-text-field>
                    <v-btn block class="rounded-lg py-5 font-weight-bold" color="primary"
                        @click="handleClickContinueWithEmail">
                        Continuar
                    </v-btn>
                    <v-btn block plain class="mt-2 rounded-lg py-4 font-weight-bold text-none" color="primary"
                        to="/login">
                        Volver a inicio de sesión
                    </v-btn>
                </v-card-text>
                <v-card-text class=" blue-grey--text text--darken-3 mt-6" v-if="currentStep === STEPS.SET_NEW_PASSWORD">
                    <v-form ref="form">
                        <v-text-field prepend-icon="mdi-lock" v-model="dataPassword.password" label="Contraseña"
                            :type="showPassword ? 'text' : 'password'" outlined
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword" loading :rules="[rules.required, rules.min]">
                            <template v-slot:progress>
                                <v-progress-linear :value="passwordReliability.value" :color="passwordReliability.color"
                                    class="mt-1" absolute height="3"></v-progress-linear>
                            </template>
                        </v-text-field>
                        <v-text-field prepend-icon="mdi-lock-check" v-model="dataPassword.repeatedPassword"
                            label="Repetir contraseña" :type="showRepeatedPassword ? 'text' : 'password'" outlined
                            :append-icon="showRepeatedPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showRepeatedPassword = !showRepeatedPassword"
                            :rules="[rules.required, rules.samePassword(dataPassword.password, dataPassword.repeatedPassword)]"></v-text-field>
                        <v-btn block class="rounded-lg py-5 mt-6 font-weight-bold" color="primary"
                            @click="handleClickContinueWithNewPassword">
                            Continuar
                        </v-btn>
                    </v-form>
                </v-card-text>
                <v-card-text class=" blue-grey--text text--darken-3"
                    v-if="currentStep === STEPS.PASSWORD_RESET_SUCCESS">
                    <v-btn block class="rounded-lg py-5 font-weight-bold" color="primary" @click="handleClickGoToApp">
                        Ir a la aplicación
                    </v-btn>
                </v-card-text>
                <v-card-text class=" blue-grey--text text--darken-3" v-if="currentStep === STEPS.ERROR">
                    <p class="text-body-2">Se ha producido un error al intentar restablecer la contraseña.</p>
                    <p>Por favor, inténtalo de nuevo.</p>
                    <v-btn block plain class="mt-2 rounded-lg py-4 font-weight-bold text-none" color="primary"
                        to="/login">
                        Volver a inicio de sesión
                    </v-btn>
                </v-card-text>
            </v-card>
            <div class="d-flex align-center justify-center  gray--text text-darken-4 mt-n8">
                <v-btn text plain class="text-capitalize" :href="LANDING_URL" target="_blank">© Slotspot</v-btn>
                <span>·</span>
                <v-btn text plain class="text-capitalize" :href="`${LANDING_URL}/contact`"
                    target="_blank">Contacto</v-btn>
                <span>·</span>
                <v-btn text plain class="text-none" :href="`${LANDING_URL}/terms`" target="_blank">Términos y
                    condiciones</v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
const STEPS = {
    SET_EMAIL: 1,
    CHECK_EMAIL: 2,
    RECHECK_EMAIL: 3,
    SET_NEW_PASSWORD: 4,
    PASSWORD_RESET_SUCCESS: 5,
    ERROR: 6
}

const STEPS_TITLES = {
    [STEPS.SET_EMAIL]: 'Restablecer la contraseña',
    [STEPS.CHECK_EMAIL]: 'Revisa tu correo electrónico',
    [STEPS.RECHECK_EMAIL]: 'Revisa tu correo electrónico',
    [STEPS.SET_NEW_PASSWORD]: 'Restablecer la contraseña',
    [STEPS.PASSWORD_RESET_SUCCESS]: 'Has cambiado con éxito tu contraseña',
    [STEPS.ERROR]: 'Se ha producido un error'
}

export default {
    name: 'PublicResetView',
    data() {
        return {
            LANDING_URL: process.env.VUE_APP_LANDING_URL,
            STEPS_TITLES,
            STEPS,
            urlServer: `${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}`,
            currentStep: STEPS.ERROR,
            email: '',
            emailError: false,
            resetEmailDialog: true,
            businessUrl: '/business',
            showPassword: false,
            showRepeatedPassword: false,
            dataPassword: {
                password: '',
                repeatedPassword: ''
            },
            passwordReliability: {
                value: 0,
                color: 'error'
            },
            rules: {
                required: value => !!value || 'Este campo es necesario',
                min: v => v.length >= 4 || 'Mínimo 4 caracteres',
                // email: email => /.+@.+\..+/.test(email) || 'Email no válido',
                samePassword: (value, repeatedPassword) => value === repeatedPassword || 'Las contraseñas no coinciden',
            },
        }
    },
    computed: {
        isMobile() {
            return window.innerWidth < 600
        },
    },
    async mounted() {

    },
    async created() {
        const token = this.$route.query.t
        if (token) {
            this.$router.replace({ query: {} })
            try {
                const response = await this.$store.dispatch('auth/passwordResetValidate', token)
                if (response.status === 200) {
                    this.currentStep = STEPS.SET_NEW_PASSWORD
                    this.token = token
                } else {
                    this.currentStep = STEPS.ERROR
                }
            } catch (error) {
                this.currentStep = STEPS.ERROR
            }
        } else {
            this.currentStep = STEPS.SET_EMAIL
        }
        this.$store.state.pendingRequests = 0
    },
    methods: {
        close() {
            this.verifyEmailDialog = false
            this.$nextTick(() => {
                this.$router.back()
            })
        },
        checkValidEmail() {
            return this.email && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.email)
        },
        async handleClickContinueWithEmail() {
            if (this.checkValidEmail()) {
                try {
                    const response = await this.$store.dispatch('auth/passwordResetRequest', this.email)
                    if (response.status === 200) {
                        this.currentStep = STEPS.CHECK_EMAIL
                    } else {
                        this.currentStep = STEPS.ERROR
                    }
                } catch (error) {
                    this.currentStep = STEPS.ERROR
                }
                this.$store.state.pendingRequests = 0
                return
            }
            this.emailError = true
        },
        async handleClickResendEmail() {
            try {
                const response = await this.$store.dispatch('auth/passwordResetRequest', this.email)
                if (response.status === 200) {
                    this.currentStep = STEPS.RECHECK_EMAIL
                } else {
                    this.currentStep = STEPS.ERROR
                }
            } catch (error) {
                this.currentStep = STEPS.ERROR
            }
            this.$store.state.pendingRequests = 0
        },
        handleClickTryOtherEmail() {
            this.email = ''
            this.currentStep = STEPS.SET_EMAIL
        },
        async handleClickContinueWithNewPassword() {
            if (this.$refs.form.validate()) {
                try {
                    const response = await this.$store.dispatch('auth/passwordReset', { token: this.token, password: this.dataPassword.password })
                    if (response.status === 200) {
                        const { businessId } = response.data
                        this.businessUrl = `/businesses/${businessId}`
                        this.currentStep = STEPS.PASSWORD_RESET_SUCCESS
                    } else {
                        this.currentStep = STEPS.ERROR
                    }
                } catch (error) {
                    this.currentStep = STEPS.ERROR
                }
                this.$store.state.pendingRequests = 0
            }
        },
        handleClickGoToApp() {
            this.$router.push(this.businessUrl)
        },
    }
}
</script>
<style scoped>
p a {
    color: #00bfa5 !important;
    text-decoration: none;
}

p a:hover {
    color: #444 !important;
}

p .action {
    color: #00bfa5 !important;
    cursor: pointer;
    font-weight: bold;
}

p .action:hover {
    color: #444 !important;
}

a {
    font-weight: bold;
    letter-spacing: 0.1px;
}
</style>
