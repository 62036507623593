<template>
    <v-snackbar v-model="snackbar.show" color="transparent" :timeout="snackbar.timeout" :multi-line="true" elevation="0"
        @click="snackbar.show = false">
        <v-alert border="left" colored-border :color="color" :type="snackbar.color" elevation="2"
            class="px-6 py-4 rounded-lg" light @click="snackbar.show = false">
            {{ snackbar.text }}
        </v-alert>
    </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'SnackBar',
    computed: {
        ...mapGetters(['snackbar']),
        color() {
            switch (this.snackbar.color) {
                case 'success':
                    return 'teal accent-4'
                case 'error':
                    return 'error'
                case 'warning':
                    return 'warning'
                default:
                    return 'info'
            }
        },
    },
    methods: {

    },
}
</script>
