<template>
    <v-container class="fill-height d-flex align-center justify-center">
        <v-card v-if="status === 'success'" class="success-card pa-8 text-center rounded-lg" elevation="3">
            <v-icon color="teal accent-4" size="64">mdi-check-circle</v-icon>
            <h1 class="my-4">¡Pago realizado con éxito!</h1>
            <p class="mt-4 font-weight-light">
                Tu compra ha sido procesada correctamente y los créditos han sido añadidos a tu cuenta.
            </p>
            <p class="font-weight-light">Gracias por confiar en nosotros para mejorar la comunicación con tus clientes.
            </p>
            <v-btn color="teal accent-4 white--text rounded-lg" class="mt-4" @click="handleClickGoesToApp">
                Ir a la aplicación
            </v-btn>
        </v-card>

        <v-card v-else class="error-card pa-8 text-center rounded-lg" elevation="3">
            <v-icon color="warning" size="64">mdi-alert-circle</v-icon>
            <h1 class="my-4">¡Ups! Algo salió mal</h1>
            <p class="mt-4 font-weight-light">
                No hemos podido completar tu pago. No se ha realizado ningún cargo en tu cuenta.
            </p>
            <p class="font-weight-light">Por favor, inténtalo de nuevo o contacta con nuestro soporte si el problema
                persiste.
            </p>
            <v-btn color="teal accent-4 white--text rounded-lg" class="mt-4" @click="handleClickRetry">
                Volver a intentar
            </v-btn>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: 'PurchaseCreditResultView',
    data() {
        return {
            status: 'success',
        }
    },
    computed: {
        businessId() {
            return this.$route.params.businessId;
        },
        sessionId() {
            return this.$route.query.session_id;
        }
    },
    methods: {
        handleClickGoesToApp() {
            const path = this.businessId ? `/businesses/${this.businessId}/settings/notifications` : '/';
            console.log('path', path);
            this.$router.push(path);
        },
        handleClickRetry() {
            this.$router.push(`/businesses/${this.businessId}/purchase-credit`);
        }
    },
    async mounted() {
        this.status = this.$route.query.status || 'error';
        if (this.status === 'success') {
            if (this.sessionId) {
                console.log('status', this.status);
                try {
                    console.log('Verifying payment...');
                    const response = await fetch(`${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}/api/v1/businesses/${this.businessId}/subscriptions/buy-credits-success?session_id=${this.sessionId}`)
                    const data = await response.json()
                    if (response.ok && data.status === 'success') {
                        this.status = 'success'
                    } else {
                        this.status = 'error'
                    }
                } catch (error) {
                    console.error("Error al verificar el pago:", error)
                    this.status = 'error'
                }
            } else {
                this.status = 'error'
            }
        }
    }
};
</script>

<style scoped>
.fill-height {
    height: 100vh;
}

.text-muted {
    color: #757575;
}

.success-card {
    max-width: 500px;
    border: 2px solid #00bfa5;
    border-radius: 10px;
}

.error-card {
    max-width: 500px;
    border: 2px solid #f44336;
    border-radius: 10px;
}
</style>
