<template>
    <div>
        <NavigationDrawer :value="!$vuetify.breakpoint.mdAndDown" :items="settingsNavigationItems">
        </NavigationDrawer>
        <v-main app>
            <router-view />
        </v-main>
    </div>
</template>

<script>
import { navigationItems } from '@/utils'
import NavigationDrawer from '@/components/business/NavigationDrawer.vue'


export default {
    name: 'BusinessLayout',
    components: {
        NavigationDrawer,
    },
    data() {
        return {
        }
    },
    computed: {
        navigationItems,
        settingsNavigationItems() {
            return this.navigationItems?.find(item => item.name === 'settings').items || []
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
