import { render, staticRenderFns } from "./EmployeesFormView.vue?vue&type=template&id=3040cc18&scoped=true"
import script from "./EmployeesFormView.vue?vue&type=script&lang=js"
export * from "./EmployeesFormView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3040cc18",
  null
  
)

export default component.exports