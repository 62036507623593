<template>
    <v-dialog v-model="dialog" persistent max-width="600">
        <v-card class="rounded-xl pa-3">
            <v-card-title>
                <span class="headline">Confirmar cambios</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="font-weight-bold">
                <div class="py-4" v-if="loading">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
                <div class="py-4" v-else>
                    <h3 class="text-center mb-4">{{ plan.title }}</h3>

                    <div class="d-flex justify-space-between align-center">
                        <span>Suscripción de {{ plan.title }}</span>
                        <span>{{ formattedPrice(planDetails.price) }}</span>
                    </div>

                    <div class="d-flex justify-space-between align-center">
                        <span class="font-weight-regular grey--text text-subtitle-2">Facturado {{ planPeriod ===
                            'monthly' ?
                            'mensualmente'
                            : 'anualmente' }} a partir de
                            hoy</span>
                    </div>

                    <div class="d-flex justify-space-between align-center mt-2">
                        <span>Ajuste (prorrateo)</span>
                        <span
                            :class="{ 'teal--text': planDetails.proration < 0, 'red--text': planDetails.proration > 0 }">{{
                                formattedPrice(planDetails.proration) }}</span>
                    </div>

                    <div class="d-flex justify-space-between align-center">
                        <span class="font-weight-regular grey--text text-subtitle-2">Crédito prorrateado por el resto de
                            tu suscripción
                            actual</span>
                    </div>

                    <v-divider class="my-4"></v-divider>

                    <div class="d-flex justify-space-between align-center mt-2">
                        <span>Subtotal</span>
                        <span>{{ formattedPrice(planDetails.subtotal) }}</span>
                    </div>

                    <div class="d-flex justify-space-between align-center mt-2">
                        <span>Impuestos ({{ planDetails.taxRate }}%)</span>
                        <span>{{ formattedPrice(planDetails.tax) }}</span>
                    </div>

                    <div class="d-flex justify-space-between align-center mt-4 font-weight-bold">
                        <span>Total adeudado hoy</span>
                        <span>{{ formattedPrice(planDetails.total) }}</span>
                    </div>

                    <v-divider class="my-4"></v-divider>

                    <div class="d-flex justify-space-between align-center mt-4">
                        <span>Forma de pago</span>
                        <span>{{ planDetails.paymentMethod }}</span>
                    </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-4" v-if="showEndOfCurrentPeriod">
                    <span>Fecha de finalización de tu plan actual</span>
                    <span>{{ endOfCurrentPeriod }}</span>
                </div>
                <span class="font-weight-regular grey--text text-subtitle-2" v-if="showEndOfCurrentPeriod">Tu plan
                    actual se cancelará al final
                    de este período y se hará efectivo el nuevo plan.</span>
            </v-card-text>

            <v-card-actions class="justify-end pb-3">
                <v-btn text color="grey darken-2" class="px-6 rounded-lg" @click="dialog = false">Cancelar</v-btn>
                <v-btn color="primary" class="px-6 rounded-lg" dark @click="updatePlan">Pagar ahora</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { SUBSCRIPTION_PLANS } from '@/consts';
import api from '@/services/api';

export default {
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        plan: {
            type: Object,
            required: true,
        },
        planPeriod: {
            type: String,
            default: 'monthly',
        },
        currentPlan: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            planDetails: {},
            endOfCurrentPeriod: null,
        };
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        showEndOfCurrentPeriod() {
            console.log('Current plan:', this.currentPlan);
            return this.currentPlan === SUBSCRIPTION_PLANS.PREMIUM;
        },
    },
    watch: {
        dialog(value) {
            console.log('Dialog:', value);
            if (value) {
                this.fetchPlanDetails();
            }
        },
    },
    methods: {
        async fetchPlanDetails() {
            this.loading = true;
            try {
                const response = await api.get(`/subscriptions/preview-plan-change?plan=${this.plan.name}&period=${this.planPeriod}${this.showEndOfCurrentPeriod ? `&endOfCurrentPeriod=true` : ''}`);
                this.planDetails = response.data.preview;
                this.endOfCurrentPeriod = response.data.endOfCurrentPeriod;
            } catch (error) {
                console.error('Error al obtener detalles del plan:', error);
            } finally {
                this.loading = false;
            }
        },
        async updatePlan() {
            try {
                const response = await api.get(`/subscriptions/update-plan?plan=${this.plan.name}&period=${this.planPeriod}${this.showEndOfCurrentPeriod ? `&endOfCurrentPeriod=true` : ''}`);
                console.log('Plan actualizado:', response.data);
                this.$emit('plan-updated', response.data);
                this.dialog = false;
            } catch (error) {
                console.error('Error al actualizar el plan:', error);
                alert('No se pudo completar el cambio de plan.');
            }
        },
        formattedPrice(amount) {
            return `${(amount / 100).toFixed(2)} €`;
        },
    },
};
</script>

<style scoped>
.py-4 {
    padding: 16px 0;
}

.font-weight-bold {
    font-weight: bold;
}
</style>