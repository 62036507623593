<template>
    <v-navigation-drawer app class="translucient-white pt-2" style="z-index: 10;" left clipped width="300"
        v-model="drawerValue">
        <div class="d-flex flex-column align-stretch" style="height: 80%; width: 100%;">
            <v-btn class="ma-3" color="primary" @click="goesToChangePlan" v-if="showUpdatePlanButton">ACTUALIZAR
                PLAN</v-btn>
            <InstallAppButton class="d-block d-md-none px-3" block></InstallAppButton>
            <div>
                <v-list nav :value="selectedItem">
                    <v-list-group v-for="(item, itemIdx) in navigationItems" :key="`item-${itemIdx}`"
                        :prepend-icon="item.icon" :append-icon="item.items?.length > 0 ? 'mdi-chevron-down' : ''"
                        no-action :color="item.items?.length > 0 ? 'grey darken-3' : 'teal accent-4'"
                        @click.stop="handleClickStopParent(itemIdx)" :value="selectedItem == itemIdx"
                        :disabled="selectedItem == itemIdx && item.items?.length === 0 || item.locked"
                        :class="{ 'hello': selectedItem == itemIdx }">
                        <template v-slot:activator>
                            <BlockingBadge :locked="item.locked" style="width: 100%;">
                                <v-list-item-content :disabled="item.locked || item.disabled">
                                    <v-list-item-title v-text="item.title" class="font-weight-bold body-2"
                                        :class="{ 'grey--text': item.locked }"></v-list-item-title>
                                </v-list-item-content>
                            </BlockingBadge>
                        </template>
                        <BlockingBadge :locked="child.locked" variant="nav-item" class="pr-2"
                            v-for="(child, childIdx) in item.items" :key="`child-${itemIdx}-${childIdx}`">
                            <v-list-item @click.stop="handleClickStopChild(itemIdx, childIdx)"
                                :input-value="`${selectedItem}-${selectedSubitem}` == `${itemIdx}-${childIdx}`"
                                color="teal accent-4" :disabled="child.locked || child.disabled">
                                <v-list-item-content>
                                    <v-list-item-title class="pl-14 font-weight-bold body-2">{{ child.title
                                        }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </BlockingBadge>
                    </v-list-group>
                </v-list>
                <v-divider class="mt-3"></v-divider>
            </div>
            <div class="pa-2"
                v-if="showUpdatePlanButton && items[selectedItem]?.items[selectedSubitem]?.name !== 'notifications'">
                <CardPurchaseCredits variant="calendar" />
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script>
import CardPurchaseCredits from '@/components/business/CardPurchaseCredits.vue'
import BlockingBadge from '@/components/business/BlockingBadge.vue'
import InstallAppButton from '@/components/common/InstallAppButton.vue'
import { goesToChangePlan } from '@/utils'

export default {
    name: 'NavigationDrawer',
    components: {
        BlockingBadge,
        CardPurchaseCredits,
        InstallAppButton,
    },
    props: {
        value: {
            type: Boolean,
        },
        items: {
            type: Array,
            required: true,
        },
        showUpdatePlanButton: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
        }
    },
    computed: {
        drawerValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        selectedItem() {
            for (let i = 0; i < this.navigationItems.length; i++) {
                const item = this.navigationItems[i]
                if (this.$route.path.includes(item.path)) {
                    return i
                }
                for (let j = 0; j < item.items?.length; j++) {
                    const child = item.items[j]
                    if (this.$route.path.includes(child.path)) {
                        return i
                    }
                }
            }
            return 0
        },
        selectedSubitem() {
            const item = this.navigationItems[this.selectedItem]
            if (item) {
                return item.items?.findIndex(child => this.$route.path.includes(child.path))
            }
            return 0
        },
        navigationItems() {
            return this.items
        }
    },
    methods: {
        goesToChangePlan,
        linkTo(path) {
            this.$router.push(path)
        },
        handleClickStopParent(itemIdx) {
            const item = this.navigationItems[itemIdx]
            if (item.items?.length > 0) {
                return
            }
            if (item.path) this.linkTo(item.path)
            if (item.href) window.open(item.href, '_blank')
            if (item.action) item.action()
        },
        handleClickStopChild(parentIdx, childIdx) {
            const item = this.navigationItems[parentIdx]
            const child = item.items[childIdx]
            if (child.path) this.linkTo(child.path)
            if (child.href) window.open(child.href, '_blank')
            if (child.action) child.action()
        },
    },
    mounted() {
        console.log(this.items[this.selectedItem])
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
