<template>
    <div>
        <v-app id="app-menu">
            <v-alert class="rounded-0 mt-n13" color="primary" dense v-if="isAlertVisible"
                style="position: sticky; top: 0; width: 100%; z-index: 1;">
                <div class="d-flex align-center justify-center white--text">
                    <v-spacer></v-spacer>
                    <span class="d-none d-md-block" style="font-weight: 300;">
                        {{
                            isTrialVersion ?
                                'Te quedan ' + daysLeftForTrial + ' días de prueba del plan Premium.'
                                : 'Estás usando la versión gratuita de Slotspot.'
                        }}
                        <a @click="goesToChangePlan" class="white--text font-weight-bold text-decoration-underline">
                            Actualiza tu plan
                        </a>
                        para
                        disfrutar de todas las funcionalidades.
                    </span>
                    <span class="d-block d-md-none text-center" style="font-weight: 300;">
                        {{
                            isTrialVersion ?
                                'Te quedan ' + daysLeftForTrial + ' días de plan Premium.'
                                :
                                'Versión gratuita de Slotspot'
                        }}
                        <br> <a @click="$router.push(`/businesses/${businessId}/prices`)"
                            class="white--text font-weight-bold text-decoration-underline">Actualiza tu plan</a></span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeAlert" class="white--text">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
            </v-alert>
            <AppBar @toggleDrawer="toggleDrawer"
                :style="isAlertVisible && ($vuetify.breakpoint.mdAndDown ? 'transform: translateY(60px);' : 'transform: translateY(52px);')" />
            <MobileNavigationDrawer v-model="drawerClick" />
            <router-view
                :class="isAlertVisible && ($vuetify.breakpoint.mdAndDown ? 'alert-margin' : 'alert-margin-md')" />
            <SnackBar />
            <LoadingLogo />
        </v-app>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AppBar from '@/components/business/AppBar.vue'
import MobileNavigationDrawer from '@/components/business/MobileNavigationDrawer.vue'
import SnackBar from '@/components/common/SnackBar.vue'
import LoadingLogo from '@/components/common/LoadingLogo.vue'
import { currentPlan, goesToChangePlan, isTrialVersion, daysLeftForTrial } from '@/utils'
import { SUBSCRIPTION_PLANS } from '@/consts'

export default {
    name: 'MainLayout',
    components: {
        AppBar,
        MobileNavigationDrawer,
        SnackBar,
        LoadingLogo
    },
    props: {
        businessId: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            drawerClick: false,
            alertClosed: false,
        }
    },
    computed: {
        ...mapGetters(['user', 'business']),
        isTrialVersion,
        daysLeftForTrial,
        isAlertVisible() {
            return (this.isTrialVersion && this.daysLeftForTrial < 15 || currentPlan() === SUBSCRIPTION_PLANS.BASIC) && !this.alertClosed
        },
    },
    methods: {
        ...mapActions(['fetchBusiness']),
        goesToChangePlan,
        toggleDrawer() {
            this.drawerClick = !this.drawerClick
        },
        closeAlert() {
            this.alertClosed = true
        },
    },
    async created() {
        if (!localStorage.getItem('token')) { // Redirect to home
            this.$router.push('/')
        }
        if (!this.business?.id) await this.fetchBusiness()
    }
}
</script>

<style>
#app-menu {
    background: #ebebeb;
}

.alert-margin-md {
    margin-top: 36px;

    & nav {
        margin-top: 36px;
    }
}

.alert-margin {
    margin-top: 32px;

    & nav {
        margin-top: 32px;
    }
}
</style>
