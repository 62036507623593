<template>
    <v-app id="app-menu">
        <!-- <h1>{{ code }}</h1> -->
    </v-app>
</template>

<script>

export default {
    name: 'DiscountView',
    props: {
        code: {
            type: String,
            default: ''
        }
    },
    created () {
        if (!this.code) {
            this.$router.push('/signup')
        }
        this.$router.push(`/signup?promoCode=${this.code}&plan=standard&period=annual`)
    }
}
</script>
