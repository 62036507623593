<template>
    <v-dialog v-model="dialog" persistent max-width="600">
        <v-card class="py-2 px-1">
            <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
            <v-card-text>
                <p v-html="dialogText" v-if="!isLimitReached"></p>
                <p v-if="isLimitReached">Has alcanzado el <strong :class="`${accentColor}--text`">límite de {{
                    this.maxLimit }} {{ this.variantName
                        }}</strong> disponibles en tu plan.</p>
                <v-progress-linear height="6" :color="accentColor" :value="usagePercentage"></v-progress-linear>
                <p class="mt-4" v-if="!isLimitReached">{{ alertText }}</p>
                <p v-else class="mt-4">Ya no puedes añadir más {{ this.variantName }}.</p>
                <v-alert class="caption mt-4" text color="primary" dense>Actualiza tu plan para
                    seguir
                    disfrutando de
                    todas las
                    funcionalidades
                    de Slotspot.</v-alert>
            </v-card-text>
            <v-card-actions class="mt-0 pt-0">
                <v-spacer></v-spacer>
                <v-btn color="grey" text @click="closeDialog" class="px-4">Cerrar</v-btn>
                <v-btn color="primary" @click="goesToChangePlan" class="px-4">Actualizar
                    plan</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { SUBSCRIPTION_LIMIT_VARIANTS } from '@/consts';
import { goesToChangePlan } from '@/utils';

const variantNames = {
    [SUBSCRIPTION_LIMIT_VARIANTS.Appointments]: 'citas este mes',
    [SUBSCRIPTION_LIMIT_VARIANTS.Services]: 'servicios',
    [SUBSCRIPTION_LIMIT_VARIANTS.Clients]: 'clientes',
    [SUBSCRIPTION_LIMIT_VARIANTS.Employees]: 'empleados',
}


const variantAlerts = {
    [SUBSCRIPTION_LIMIT_VARIANTS.Appointments]: 'Una vez que alcances el límite de citas, no podrás crear más reservas y las reservas creadas por tus clientes no se mostrarán.',
    [SUBSCRIPTION_LIMIT_VARIANTS.Services]: 'Una vez que alcances el límite de servicios, no podrás crear más servicios.',
    [SUBSCRIPTION_LIMIT_VARIANTS.Clients]: 'Una vez que alcances el límite de clientes, no podrás crear más clientes y los clientes creados por la web de clientes no se mostrarán.',
    [SUBSCRIPTION_LIMIT_VARIANTS.Employees]: 'Una vez que alcances el límite de empleados, no podrás crear más empleados.',
}

export default {
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        variant: {
            type: String,
            required: true,
        },
        maxLimit: {
            type: Number,
            required: true,
        },
        currentCount: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
        }
    },
    computed: {
        dialog: {
            get() {
                return this.maxLimit === 0 ? false : this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        isLimitReached() {
            return this.currentCount >= this.maxLimit
        },
        variantName() {
            return variantNames[this.variant];
        },
        dialogTitle() {
            return `Límite de ${this.variantName}`;
        },
        usagePercentage() {
            return (this.currentCount / this.maxLimit) * 100;
        },
        accentColor() {
            if (this.usagePercentage < 50) {
                return 'primary';
            } else if (this.usagePercentage < 75) {
                return 'warning';
            } else {
                return 'red';
            }
        },
        dialogText() {
            return `Has usado <strong class="${this.accentColor}--text">${this.currentCount} de ${this.maxLimit} ${this.variantName}</strong> disponibles en tu plan.`;
        },
        alertText() {
            return variantAlerts[this.variant];
        }
    },
    methods: {
        goesToChangePlan,
        closeDialog() {
            console.log('close dialog')
            this.dialog = false;
        }
    },
}
</script>