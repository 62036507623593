<template>
    <div :style="{ 'width: 100%;': block }" v-if="!isInstalled">
        <!-- Botón para Android -->
        <v-btn v-if="os === 'Android'" v-show="showInstallButton" color="black" :block="block"
            class="mr-3 pa-3 px-5 font-weight-black elevation-0" @click="installAppAndroid" dark>
            <v-img :src="require('@/assets/google-play-store.png')" style="max-width: 24px;" width="24" height="24"
                class="mr-2"></v-img>
            <div class="d-flex flex-column align-start">
                <span class="font-weight-light" style="font-size: 8px;">Obtener en</span>
                <span class="font-weight-black">Google Play</span>
            </div>
        </v-btn>

        <!-- Botón para iOS -->
        <v-btn v-else-if="os === 'iOS'" color="black" class="mr-3 pa-3 px-5 font-weight-black elevation-0"
            :block="block" @click="installAppIOS" dark>
            <v-img :src="require('@/assets/mac-os.png')" style="max-width: 24px;" width="24" height="24"
                class="mr-2"></v-img>
            <div class="d-flex flex-column align-start">
                <span class="font-weight-light" style="font-size: 8px;">Instalar desde</span>
                <span class="font-weight-black">App Store</span>
            </div>
        </v-btn>

        <!-- Botón genérico para otros sistemas -->
        <v-btn v-else color="black" class="mr-3 pa-3 px-5 font-weight-black elevation-0" :block="block"
            @click="installAppGeneric" dark>
            <v-icon class="mr-3" v-if="$vuetify.breakpoint.mdAndUp">
                mdi-monitor-arrow-down-variant
            </v-icon>
            <v-icon class="mr-3" v-else>
                mdi-cellphone-arrow-down-variant
            </v-icon>
            <div class="d-flex flex-column align-start">
                <span class="font-weight-light" style="font-size: 8px;">Instalar en</span>
                <span class="font-weight-black">{{ os }}</span>
            </div>
        </v-btn>
    </div>
</template>

<script>
export default {
    props: {
        block: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            deferredPrompt: null, // Almacena el evento antes de instalar (Android)
            showInstallButton: false, // Controla la visibilidad del botón de instalación para Android (cuando la PWA es instalable)
            isInstalled: false, // Indica si la app ya está instalada
        };
    },
    computed: {
        // Computed property que detecta el sistema operativo
        os() {
            const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
            // Detectar Android
            if (/android/i.test(userAgent)) {
                return 'Android';
            }
            // Detectar iOS (iPhone, iPad o iPod)
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return 'iOS';
            }
            // Para otros sistemas, se analiza navigator.platform
            const platform = window.navigator.platform;
            if (/Win/.test(platform)) {
                return 'Windows';
            }
            if (/Mac/.test(platform)) {
                return 'MacOS';
            }
            if (/Linux/.test(platform)) {
                return 'Linux';
            }
            return 'Desconocido';
        },
    },
    watch: {
        // Observa la propiedad isInstalled para emitir un evento
        os(value) {
            console.log('Sistema operativo detectado:', value);
        },
    },
    mounted() {
        // Verifica si la app ya está instalada
        if (
            window.matchMedia('(display-mode: standalone)').matches ||
            window.navigator.standalone === true
        ) {
            this.isInstalled = true;
        } else {
            // Escucha el evento beforeinstallprompt (aplicable a Android/PWA)
            window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
        }
        console.log('Sistema operativo detectado:', this.os);
    },
    beforeDestroy() {
        window.removeEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
    },
    methods: {
        // Maneja el evento beforeinstallprompt para Android
        handleBeforeInstallPrompt(e) {
            e.preventDefault(); // Previene el prompt automático
            this.deferredPrompt = e; // Almacena el evento para usarlo después
            this.showInstallButton = true; // Muestra el botón de instalación para Android
        },
        // Método para instalar la app en Android
        installAppAndroid() {
            if (this.deferredPrompt) {
                this.deferredPrompt.prompt(); // Muestra el prompt de instalación
                this.deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                        console.log('El usuario aceptó instalar la app.');
                    } else {
                        console.log('El usuario rechazó la instalación.');
                    }
                    // Resetea la variable y oculta el botón
                    this.deferredPrompt = null;
                    this.showInstallButton = false;
                });
            } else {
                // Si no hay deferredPrompt, podrías redirigir a la Play Store
                // window.location.href = 'https://play.google.com/store/apps/details?id=tu.app.id';
                alert('Ha habido un error al instalar la app.');
            }
        },
        // Método para mostrar instrucciones de instalación en iOS
        installAppIOS() {
            alert(
                'Para instalar la app en iOS, presiona el botón de compartir y selecciona "Añadir a pantalla de inicio".'
            );
        },
        // Método genérico para otros sistemas operativos
        installAppGeneric() {
            this.installAppAndroid()
        },
    },
};
</script>