import api from "@/services/api";
import { getFeatureValue, filterAppointmentsOfCurrentMonth } from "@/utils";
import { FEATURES } from "@/consts";

export default {
    namespaced: false,
    state: {
        appointments: [],
    },
    getters: {
        appointments: state => state.appointments,
        currentAppointmentsCount: state => {
            return filterAppointmentsOfCurrentMonth(state.appointments).length
        }
    },
    mutations: {
        setAppointments(state, appointments) {
            state.appointments = appointments
        },
    },
    actions: {
        async fetchAppointments({ commit }) {
            const response = await api.get(`appointments`, { params: {} })
            if (response.status === 200) {
                const appointments = response.data.appointments
                // Filtrar y dejar:
                // - Del mes actual las primeras maxBookings reservas creadas
                // - Del mes actual para atrás todas las reservas
                const today = new Date()
                const currentMonth = today.getMonth()
                const currentYear = today.getFullYear()
                const currentAppointments = filterAppointmentsOfCurrentMonth(appointments)
                const maxBookings = getFeatureValue(FEATURES.MonthlyAppointments)
                const currentAppointmentsFirstByPlan = maxBookings > 0 ? currentAppointments.slice(0, maxBookings) : currentAppointments
                const firstDayOfCurrentMonth = new Date(currentYear, currentMonth, 1)
                const firstDayOfNextMonth = new Date(currentYear, currentMonth + 1, 1)
                const pastAndFutureAppointments = appointments.filter(appointment => {
                    const appointmentDate = new Date(appointment.start)
                    return appointmentDate < firstDayOfCurrentMonth || appointmentDate > firstDayOfNextMonth
                })

                commit('setAppointments', [...currentAppointmentsFirstByPlan, ...pastAndFutureAppointments])
            }
        },
        async fetchAppointment({ commit }, id) {
            const response = await api.get(`appointments/${id}`, { params: {} })
            if (response.status === 200) {
                return response.data.appointment
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al obtener la reserva', color: 'error' })
            }
        },
        async createAppointment({ commit }, appointment) {
            // commit('startLoading', { root: true })
            const response = await api.post(`appointments`, appointment)
            // commit('stopLoading', { root: true })
            if (response.status === 201) {
                commit('setSnackbar', { show: true, text: 'Reserva creada correctamente', color: 'success' })
                return response.data.appointment
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al crear la reserva', color: 'error' })
            }
        },
        async updateAppointment({ commit }, appointment) {
            // commit('startLoading', { root: true })
            const response = await api.put(`appointments/${appointment.id}`, appointment)
            // commit('stopLoading', { root: true })
            if (response.status === 200) {
                commit('setSnackbar', { show: true, text: 'Reserva actualizada correctamente', color: 'success' })
                return response.data.appointment
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al actualizar la reserva', color: 'error' })
            }
        },
        async deleteAppointment({ commit }, id) {
            const response = await api.delete(`appointments/${id}`, { params: {} })
            if (response.status === 204) {
                commit('setSnackbar', { show: true, text: 'Reserva eliminada correctamente', color: 'success' })
                return true
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al eliminar la reserva', color: 'error' })
                return false
            }
        },
    },
};
