<template>
    <div class="pa-2 pa-lg-0">
        <v-toolbar flat color="transparent">
            <div class="d-flex" style="width: 100%">
                <h2 class="ml-n5 pl-0">
                    Clientes
                    <v-chip outlined label class="ml-4 mt-n1 rounded-lg" color="grey darken-1">{{ filteredClients.length
                        }}</v-chip>
                </h2>
                <BlockingBadge :locked="!isClientsBlacklist">
                    <v-btn :outlined="!isClientBlockedFilter" label class="ml-3 ml-md-4 mt-1 rounded-lg text-none py-4"
                        small :color="isClientBlockedFilter ? 'primary' : 'grey darken-1'"
                        @click="isClientBlockedFilter = !isClientBlockedFilter" :disabled="!isClientsBlacklist">
                        Bloqueados
                    </v-btn>
                </BlockingBadge>
            </div>
            <v-spacer></v-spacer>
            <BlockingBadge :locked="!canAddClients()" class="mr-n6">
                <v-btn elevation="0" :dark="canAddClients()" color="teal accent-4"
                    class="mt-1 mt-md-2 px-6 py-md-5 rounded-lg text-none" @click="$router.push('clients/form/new')"
                    :disabled="!canAddClients()">
                    <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-account-plus</v-icon>
                    <span class="d-none d-md-block">Añadir cliente</span>
                </v-btn>
            </BlockingBadge>
        </v-toolbar>
        <v-row class="pt-lg-2 pt-4">
            <v-col cols="12" lg="6" v-for="client in filteredClients" :key="client.id" class="pa-1">
                <v-card class="px-1 pt-1 d-flex flex-row" style="position: relative;" outlined
                    :color="isClientBlocked(client) ? 'red lighten-4' : ''"
                    @click="$router.push(`clients/details/${client.id}`)">
                    <v-avatar size="65" :color="client.color" style="opacity: 0.7"
                        class="ma-2 rounded-lg white--text text-h5" tile>{{ client.initials }}</v-avatar>
                    <v-card-text class="pl-0 pt-1 pb-0 ml-2">
                        <p class="pa-0 ma-0 mt-1">
                            <span class="font-weight-bold subtitle-2" style="line-height:0%">{{ client.fullName
                                }}</span>
                        </p>
                        <p class="pa-0 ma-0 mt-n1">
                            <span class="font-weight-light subtitle-2">{{ client.email }}</span>
                        </p>
                        <p class="pa-0 mt-n1">
                            <span class="font-weight-light subtitle-2">{{ client.phoneNumber }}</span>
                        </p>
                    </v-card-text>
                    <v-card-actions class="d-flex flex-column align-start pa-0"
                        style="position: absolute; top:0; right: 0;" v-if="isClientBlocked(client)">
                        <v-icon class="pt-2 pr-2" color="red">mdi-cancel</v-icon>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BlockingBadge from '@/components/business/BlockingBadge'
import { canAddClients, isFeatureAvailable } from '@/utils'
import { FEATURES } from '@/consts';

export default {
    name: 'ListView',
    components: {
        BlockingBadge
    },
    data() {
        return {
            isClientBlockedFilter: false
        }
    },
    computed: {
        ...mapGetters(['clients']),
        filteredClients() {
            return this.isClientBlockedFilter ?
                this.clients.filter(client => this.isClientBlocked(client))
                : this.clients
        },
        isClientsBlacklist() {
            return isFeatureAvailable(FEATURES.ClientsBlacklist)
        }
    },
    created() {
        this.fetchClients()
    },
    methods: {
        ...mapActions(['fetchClients']),
        canAddClients,
        isClientBlocked(client) {
            return client.isBlocked && this.isClientsBlacklist
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
