<template>
    <v-app-bar color="translucient-white" class="elevation-0" app clipped-left outlined>
        <v-app-bar-nav-icon @click="$emit('toggleDrawer')" v-if="$vuetify.breakpoint.mdAndDown"></v-app-bar-nav-icon>
        <v-spacer v-if="$vuetify.breakpoint.mdAndDown"></v-spacer>
        <v-img :src="require('@/assets/logo-teal.png')" max-height="30" max-width="200" contain
            @click="$router.push('/')" style="cursor: pointer;" class="ml-7 ml-md-0"></v-img>
        <v-spacer></v-spacer>
        <BlockingBadge :locked="section.locked" v-for="section in navigationItems" :key="section.title"
            class="d-none d-lg-block">
            <v-btn text @click="$router.push(section.path)" class="text-none font-weight-bold body-2" :class="{
                'teal--text text--accent-4': $route.path.includes(section.path),
                'grey--text text--darken-2': !$route.path.includes(section.path)
            }" :disabled="section.locked">
                <v-icon class="mr-2" :color="$route.path.includes(section.path) ? 'teal accent-4' : 'grey darken-1'">{{
                    section.icon }}</v-icon>{{ section.title }}
            </v-btn>
        </BlockingBadge>
        <v-spacer></v-spacer>
        <InstallAppButton class="d-none d-md-flex"></InstallAppButton>
        <v-btn color="teal accent-4" class="mr-3 pa-3 px-5 font-weight-black d-none d-md-flex elevation-0"
            @click="goesToChangePlan" dark>Actualizar
            plan</v-btn>
        <AvatarButton />
    </v-app-bar>
</template>

<script>
import { goesToChangePlan, navigationItems } from '@/utils';
import BlockingBadge from '@/components/business/BlockingBadge.vue';
import InstallAppButton from '@/components/common/InstallAppButton.vue';
import AvatarButton from '../common/AvatarButton.vue';

export default {
    name: 'AppBar',
    components: {
        BlockingBadge,
        InstallAppButton,
        AvatarButton,
    },
    data() {
        return {
            selectedSection: 'activity',
            businessId: localStorage.getItem('businessId'),
        }
    },
    computed: {
        navigationItems,
    },
    methods: {
        goesToChangePlan,
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
    text-decoration: none;
}
</style>
