<template>
    <div>
        <v-card class="body-2 font-weight-bold elevation-0 rounded-lg px-2"
            :class="variant === 'calendar' ? 'grey lighten-4 py-2 pt-1' : ''" style="height: 100%;">
            <v-card-title class="pb-0 font-weight-bold"
                :class="variant === 'calendar' ? 'px-0 mt-2 text-uppercase body-2 text-center d-flex justify-center pt-0' : 'px-3 pt-4 pt-md-0'">Créditos</v-card-title>
            <v-card-text outlined class=" d-flex flex-column px-4 px-md-2 rounded-lg py-0 pb-0"
                :class="variant === 'calendar' ? 'pb-3' : 'pb-md-6'" style="width: 100%;">
                <div class="d-flex flex-row justify-center align-center pt-3" style="width: 100%;">
                    <v-icon class="pr-2" :dense="variant === 'calendar'">mdi-whatsapp</v-icon>
                    <span class="pr-2 text-no-wrap font-weight-bold" style="min-width: 90px;">Whatsapp</span>
                    <v-progress-linear style="width: 100%;" class="rounded-lg text--darken-4 font-weight-bold"
                        :class="`${whatsappAccentColor}--text`" :height="variant === 'calendar' ? 18 : 24"
                        :color="whatsappAccentColor" :value="whatsappCredits">
                        {{ whatsappCredits }}
                    </v-progress-linear>
                </div>
                <div class="d-flex flex-row justify-center align-center pt-3" style="width: 100%;">
                    <v-icon class="pr-2" :dense="variant === 'calendar'">mdi-message-text-outline</v-icon>
                    <span class="pr-2 text-no-wrap font-weight-bold" style="min-width: 90px;">SMS</span>
                    <v-progress-linear style="width: 100%;" class="rounded-lg text--darken-4 font-weight-bold"
                        :class="`${smsAccentColor}--text`" :height="variant === 'calendar' ? 18 : 24"
                        :color="smsAccentColor" :value="smsCredits">
                        {{ smsCredits }}
                    </v-progress-linear>
                </div>
            </v-card-text>
            <v-card-actions class="px-3 py-6 d-md-none d-block" v-if="variant !== 'calendar'">
                <v-btn block color="primary" class="font-weight-bold rounded-lg" @click="handleClickPurchaseButton">
                    <v-icon left>mdi-cash-plus</v-icon>
                    Comprar créditos
                </v-btn>
            </v-card-actions>
            <v-card-actions class="px-2 d-block" v-if="variant === 'calendar'">
                <v-btn x-small block color="primary" class="py-4 font-weight-bold rounded-lg"
                    @click="handleClickPurchaseButton">
                    <v-icon left>mdi-cash-plus</v-icon>
                    Comprar créditos
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-btn v-if="variant !== 'calendar'" color="primary" class="font-weight-bold d-none d-md-block rounded-lg"
            style="transform: translateY(100%)" bottom right absolute @click="handleClickPurchaseButton">
            <v-icon left>mdi-cash-plus</v-icon>
            Comprar créditos
        </v-btn>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'CardPurchaseCredits',
    props: {
        variant: {
            type: String,
            default: 'default',
        },
    },
    data() {
        return {
            maxLimit: 100,
        };
    },
    computed: {
        ...mapGetters(['business']),
        smsCredits() {
            return this.business.smsCredits;
        },
        whatsappCredits() {
            return this.business.whatsappCredits;
        },
        whatsappAccentColor() {
            return this.whatsappCredits < 30 ? 'red' : 'teal';
        },
        smsAccentColor() {
            return this.smsCredits < 30 ? 'red' : 'teal';
        }
    },
    methods: {
        handleClickPurchaseButton() {
            const businessId = this.$route.params.businessId;
            this.$router.push(`/businesses/${businessId}/purchase-credit`);
        },
    },
};
</script>