<template>
    <div>
        <div class="d-none d-md-block"></div>
        <v-dialog v-model="dialog" persistent max-width="600" fullscreen hide-overlay
            transition="dialog-bottom-transition">
            <v-card>
                <v-card-title class="headline">
                    <v-img :src="require('@/assets/logo-teal.png')" :max-height="$vuetify.breakpoint.mdAndUp ? 60 : 35"
                        :max-width="$vuetify.breakpoint.mdAndUp ? 250 : 200" contain @click="$router.push('/')"
                        style="cursor: pointer;"></v-img>
                    <v-spacer></v-spacer>
                    <v-btn color="teal accent-4" class="mr-3 pa-3 px-5 font-weight-bold d-none d-md-flex elevation-0"
                        @click="handleClickClose" dark>
                        Cerrar
                    </v-btn>
                    <v-btn icon @click="handleClickClose" class="d-md-none">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-main>
                        <v-container class="pb-15 pa-0 pa-lg-15 pt-lg-0 d-flex justify-center flex-column" fluid>
                            <div class="d-none d-md-block">
                                <h3 style="font-size: 25px; font-weight: 300;" class="text-center mb-10">
                                    Aprovecha nuestras funcionalidades al máximo</h3>
                                <h1 style="font-size: 52px;" class="text-center">Selecciona tu plan</h1>
                            </div>
                            <div class="d-md-none d-block">
                                <h3 style="font-size: 25px; font-weight: 300; line-height: 1.8rem;"
                                    class="text-center mb-6">
                                    Aprovecha nuestras funcionalidades al máximo</h3>
                                <h1 style="font-size: 52px; line-height: 3rem;" class="text-center">Selecciona tu
                                    plan
                                </h1>
                            </div>
                            <div class="py-8 d-flex justify-center">
                                <v-btn large class="elevation-0 rounded-lg rounded-r-0"
                                    :color="planPeriod == 'monthly' ? 'teal accent-4' : ''"
                                    :dark="planPeriod == 'monthly'" @click="planPeriod = 'monthly'">Mensual</v-btn>
                                <v-btn large class="elevation-0 rounded-lg rounded-l-0" @click="planPeriod = 'annual'"
                                    :color="planPeriod == 'annual' ? 'teal accent-4' : ''"
                                    :dark="planPeriod == 'annual'">Anual</v-btn>
                            </div>
                            <v-row class="d-flex justify-center">
                                <v-col cols="12" md="6" lg="3" class="px-0 px-md-3" v-for="plan in plans"
                                    :key="plan.title">
                                    <v-card outlined height="100%" class="rounded-lg d-flex flex-column">
                                        <div v-if="plan.name === SUBSCRIPTION_PLANS.STANDARD && currentPlan === SUBSCRIPTION_PLANS.BASIC || plan.name === SUBSCRIPTION_PLANS.PREMIUM && currentPlan === SUBSCRIPTION_PLANS.STANDARD"
                                            style="font-size: medium;"
                                            class="text-center teal accent-4 py-1 white--text">
                                            Recomendado
                                        </div>
                                        <div v-else style="height: 15px;"
                                            class="text-center py-0 white--text teal accent-4">
                                            &nbsp;
                                        </div>
                                        <v-card-title
                                            class=" pa-10 d-flex justify-center flex-column text-break text-center">
                                            <h2 style="font-size: 25px;" class="teal--text text--accent-4">{{
                                                plan.title
                                                }}
                                            </h2>
                                            <p style="font-size: 14px; line-height: 1.4rem;" class="pt-3">{{
                                                plan.description }}</p>
                                            <h1 style="font-size: 48px;" class="mt-6">{{ plan.price[planPeriod]
                                                }}<span style="font-size: 28px;"> {{ plan.currency }}{{
                                                    `/${plan.period[planPeriod]}` }}*</span></h1>
                                            <v-btn large color="primary" class="px-6 py-5 rounded-lg mt-10"
                                                :disabled="plan.name === currentPlan"
                                                @click="previewPlanChange(plan, planPeriod)"
                                                target="_blank">Contratar</v-btn>
                                        </v-card-title>
                                        <v-divider></v-divider>
                                        <v-card-text class="pa-6">
                                            <div v-for="(item, idx) in plan.features" :key="`${plan.title}-${idx}`"
                                                style="font-size: 15px;"
                                                class="font-weight-bold d-flex align-center py-2">
                                                <v-icon left color="teal accent-4" class="mr-3">mdi-check</v-icon>
                                                <span>{{ item.text }}</span>
                                            </div>
                                        </v-card-text>
                                        <v-card-actions class="pa-10 d-flex justify-center pt-0 mt-auto">
                                            <a @click="goesToManageSubscriptionUrl"
                                                class="teal--text text--accent-4 font-weight-bold"
                                                v-if="plan.name === currentPlan">Administrar
                                                suscripción</a>
                                            <span>* Precio sin IVA</span>
                                        </v-card-actions>

                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-main>

                </v-card-text>
            </v-card>
            <PlanCheckoutDialog v-model="planCheckoutDialog" :plan="selectedPlan" :planPeriod="planPeriod"
                :currentPlan="currentPlan" @plan-updated="handlePlanUpdated" />
        </v-dialog>
    </div>
</template>

<script>
import PlanCheckoutDialog from '@/components/business/PlanCheckoutDialog.vue';
import { mapActions, mapGetters } from 'vuex';
import api from '@/services/api';
import { goesToManageSubscriptionUrl, isTrialVersion, currentPlan } from '@/utils';
import { SUBSCRIPTION_PLANS } from '@/consts';

export default {
    name: 'PricesView',
    components: {
        PlanCheckoutDialog
    },
    data() {
        return {
            SUBSCRIPTION_PLANS,
            dialog: false,
            planCheckoutDialog: false,
            selectedPlan: {},
            planPeriod: 'annual',
        }
    },
    computed: {
        ...mapGetters(['business']),
        isTrialVersion,
        currentPlan() {
            return currentPlan(true)
        },
        plans() {
            return [
                {
                    title: 'Plan Estándar',
                    name: SUBSCRIPTION_PLANS.STANDARD,
                    description: 'Ideal para empresas consolidadas con cifras de facturación y clientes estables',
                    price: {
                        monthly: '12,99',
                        annual: '9,99'
                    },
                    period: {
                        monthly: 'mes',
                        annual: 'mes'
                    },
                    currency: '€',
                    features: [
                        { icon: 'mdi-check', text: 'Todo lo incluido en el Plan Gratuito' },
                        { icon: 'mdi-check', text: '600 reservas al mes' },
                        { icon: 'mdi-check', text: 'Clientes ilimitados' },
                        { icon: 'mdi-check', text: 'Hasta 5 tipos de servicios' },
                        { icon: 'mdi-check', text: 'Hasta 3 empleados' },
                        { icon: 'mdi-check', text: 'Perfiles de clientes con historial de reservas' },
                        { icon: 'mdi-check', text: 'Notas internas de clientes' },
                        { icon: 'mdi-check', text: 'Configuración de vacaciones' },
                        { icon: 'mdi-check', text: 'Generación de código QR para tu negocio' },
                        { icon: 'mdi-check', text: 'Bloqueo de clientes' },
                        { icon: 'mdi-check', text: 'Personalización de perfil de empresa' },
                        { icon: 'mdi-check', text: 'Configuración de horario partido' },
                        { icon: 'mdi-check', text: 'Recordatorio de cita al cliente' },
                        { icon: 'mdi-check', text: 'Notificaciones por SMS y Whatsapp' },
                    ]
                },
                {
                    title: 'Plan Premium',
                    name: SUBSCRIPTION_PLANS.PREMIUM,
                    description: 'Con funcionalidades avanzadas para analizar el rendimiento del negocio y optimizar la gestión',
                    price: {
                        monthly: '27,99',
                        annual: '23,99'
                    },
                    period: {
                        monthly: 'mes',
                        annual: 'mes'
                    },
                    currency: '€',
                    features: [
                        { icon: 'mdi-check', text: 'Todo lo incluido en el Plan Standard' },
                        { icon: 'mdi-check', text: 'Reservas ilimitadas' },
                        { icon: 'mdi-check', text: 'Clientes ilimitados' },
                        { icon: 'mdi-check', text: 'Tipos de servicios ilimitados' },
                        { icon: 'mdi-check', text: 'Empleados ilimitados' },
                        { icon: 'mdi-check', text: 'Supresión de publicidad de Slotspot' },
                        { icon: 'mdi-check', text: 'Perfiles de clientes avanzados' },
                        { icon: 'mdi-check', text: 'Configuración de políticas de cancelación' },
                        { icon: 'mdi-check', text: 'Estadísticas avanzadas' },
                        { icon: 'mdi-check', text: 'Exportación de datos de clientes' },
                        { icon: 'mdi-check', text: 'Subdominio de empresa personalizable' },
                    ]
                }
            ]
        }
    },
    methods: {
        ...mapActions(['fetchBusiness']),
        goesToManageSubscriptionUrl,
        async previewPlanChange(plan, period) {
            if (this.currentPlan !== SUBSCRIPTION_PLANS.BASIC && !this.isTrialVersion) {
                this.selectedPlan = plan
                this.planPeriod = period
                this.planCheckoutDialog = true
            } else {
                const response = await api.get(`/subscriptions/create-checkout-session/${plan.name}/${period}`)
                const redirectUrl = response.data.url
                window.location = redirectUrl
            }
        },
        handlePlanUpdated() {
            console.log('redirectToSuccesPage')
            const businessId = this.$route.params.businessId
            this.$router.push(`/businesses/${businessId}/subscription-result?status=update-success`)
        },
        handleClickClose() {
            const backPath = this.$route.query.backPath || '/'
            if (backPath.includes('subscription-result') || backPath.includes('prices')) {
                const businessId = this.$route.query.businessId
                this.$router.push(`/businesses/${businessId}/profile`)
            } else {
                this.$router.back()
            }
        }
    },
    async created() {
        this.dialog = true
        if (!this.business?.id) await this.fetchBusiness()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
