import authApi from "@/services/authApi";
import api from "@/services/api";

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem("token") || "",
        status: "",
        user: {},
    },
    getters: {
        isLoggedIn: (state) => !!state.token,
        authStatus: (state) => state.status,
    },
    mutations: {
        authRequest(state) {
            state.status = "loading";
        },
        authSuccess(state, { token, user }) {
            // Aceptar un objeto
            state.status = "success";
            state.token = token;
            state.user = user;
        },
        authError(state) {
            state.status = "error";
        },
        logout(state) {
            state.status = "";
            state.token = "";
        },
    },
    actions: {
        login({ commit }, user) {
            return new Promise((resolve, reject) => {
                commit("authRequest");
                authApi
                    .post("/login", user) // Cambiar `api` por `authApi` si se separa la configuración
                    .then((resp) => {
                        const { token, user, businessId } = resp.data;
                        localStorage.setItem("token", token);
                        localStorage.setItem("user", JSON.stringify(user));
                        localStorage.setItem("businessId", businessId);
                        api.defaults.baseURL = `${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}/api/v1/businesses/${businessId}/`;
                        // Configurar headers de autorización global si se desea
                        commit("authSuccess", { token, user }); // Pasar un objeto
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit("authError");
                        localStorage.removeItem("token"); // Asegurarse de limpiar el token
                        reject(err);
                    });
            });
        },
        logout({ commit }) {
            // return new Promise((resolve) => {
            commit("logout");
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("businessId");
            delete api.defaults.headers.common["Authorization"];
            window.location.reload();
            // resolve();
            // });
        },
        signUp({ commit }, user) {
            return new Promise((resolve, reject) => {
                commit("authRequest"); // opcional: manejar el estado de carga
                authApi
                    .post("/sign-up", user) // Usar authApi si has creado una instancia separada para autenticación
                    .then((resp) => {
                        // Opcional: manejar el token si el servidor lo envía al registrarse
                        const { user, businessId, token } = resp.data;
                        localStorage.setItem("token", token);
                        localStorage.setItem("user", JSON.stringify(user));
                        localStorage.setItem("businessId", businessId);
                        api.defaults.baseURL = `${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}/api/v1/businesses/${businessId}/`;
                        commit("authSuccess", token, user); // Si deseas autenticar al usuario directamente después del registro
                        commit("authSuccess", resp.data); // Si el servidor responde con los datos del usuario
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit("authError", err); // Manejar errores de autenticación
                        localStorage.removeItem("token"); // Asegurarse de limpiar el token si estaba presente
                        reject(err);
                    });
            });
        },
        async verifyEmail({ commit }, token) {
            return new Promise((resolve, reject) => {
                commit("authRequest");
                authApi
                    .get(`/verify-email/${token}`)
                    .then((resp) => {
                        const { token, user, businessId } = resp.data;
                        localStorage.setItem("token", token);
                        localStorage.setItem("user", JSON.stringify(user));
                        localStorage.setItem("businessId", businessId);
                        api.defaults.baseURL = `${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}/api/v1/businesses/${businessId}/`;
                        commit("authSuccess", { token, user }); // Pasar un objeto
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit("authError");
                        localStorage.removeItem("token"); // Asegurarse de limpiar el token
                        reject(err);
                    });
            });
        },
        async passwordResetRequest({ commit }, email) {
            return new Promise((resolve, reject) => {
                commit("authRequest");
                authApi
                    .post(`/reset`, { email })
                    .then((resp) => {
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit("authError");
                        reject(err);
                    });
            });
        },
        async passwordResetValidate({ commit }, token) {
            return new Promise((resolve, reject) => {
                commit("authRequest");
                authApi
                    .get(`/reset/${token}`)
                    .then((resp) => {
                        const { token, user, businessId } = resp.data;
                        localStorage.setItem("token", token);
                        localStorage.setItem("user", JSON.stringify(user));
                        localStorage.setItem("businessId", businessId);
                        api.defaults.baseURL = `${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}/api/v1/businesses/${businessId}/`;
                        commit("authSuccess", { token, user }); // Pasar un objeto
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit("authError");
                        localStorage.removeItem("token"); // Asegurarse de limpiar el token
                        reject(err);
                    });
            });
        },
        async passwordReset({ commit }, { token, password }) {
            return new Promise((resolve, reject) => {
                commit("authRequest");
                authApi
                    .post(`/reset/${token}`, { password })
                    .then((resp) => {
                        const { token, user, businessId } = resp.data;
                        localStorage.setItem("token", token);
                        localStorage.setItem("user", JSON.stringify(user));
                        localStorage.setItem("businessId", businessId);
                        api.defaults.baseURL = `${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}/api/v1/businesses/${businessId}/`;
                        commit("authSuccess", { token, user }); // Pasar un objeto
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit("authError");
                        localStorage.removeItem("token"); // Asegurarse de limpiar el token
                        reject(err);
                    });
            });
        },
    },
};
