<template>
    <v-dialog v-model="addressDialog" max-width="900" :fullscreen="$vuetify.breakpoint.mdAndDown">
        <v-card class="d-flex flex-column">
            <v-card-title class="font-weight-bold py-6" style="font-size: 25px;">Dirección</v-card-title>
            <v-card-text class="flex-grow-1">
                <v-text-field v-model="address" outlined dense prepend-inner-icon="mdi-search-web"
                    placeholder="Comienza a escribir la dirección, nosotros te ayudaremos a encontrarla..."
                    :loading="isLoading" ref="autocomplete" clearable></v-text-field>
                <v-card outlined class="pa-5">
                    <v-row>
                        <v-col cols="6" md="3">
                            <p class="font-weight-bold">Calle</p>
                        </v-col>
                        <v-col cols="6" md="9">
                            <p class="body-1">{{ addressObject.address }}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="3">
                            <p class="font-weight-bold">Localidad</p>
                        </v-col>
                        <v-col cols="6" md="9">
                            <p class="body-1">{{ addressObject.locality }}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="3">
                            <p class="font-weight-bold">Ciudad</p>
                        </v-col>
                        <v-col cols="6" md="9">
                            <p class="body-1">{{ addressObject.city }}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="3">
                            <p class="font-weight-bold">País</p>
                        </v-col>
                        <v-col cols="6" md="9">
                            <p class="body-1">{{ addressObject.country }}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="3">
                            <p class="font-weight-bold">Código Postal</p>
                        </v-col>
                        <v-col cols="6" md="9">
                            <p class="body-1">{{ addressObject.postalCode }}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="3">
                            <p class="font-weight-bold">Ubicación</p>
                        </v-col>
                        <v-col cols="6" md="9">
                            <p class="body-1">
                                {{ addressObject.latitude ? `${addressObject.latitude}ºN` : '' }}
                                {{ addressObject.longitude ? `, ${addressObject.longitude}ºW` : '' }}
                            </p>
                        </v-col>
                    </v-row>
                    <!-- TODO: Incluir mapa -->
                </v-card>
            </v-card-text>
            <v-card-actions class="py-lg-6 pb-6">
                <v-spacer></v-spacer>
                <div class="d-flex flex-column justify-end align-end">
                    <span class="caption mx-4 mb-4">Recuerda que debes guardar los cambios en la vista global</span>
                    <div class="d-flex justify-end align-center">
                        <v-btn color="grey lighten-1" class="mr-3 pa-5 px-6 font-weight-black elevation-0"
                            @click="close()" outlined><span class="grey--text text--darken-1">Cancelar</span></v-btn>
                        <v-btn color="teal accent-4" class="mr-3 pa-5 px-6 font-weight-black elevation-0"
                            @click="save()" dark>Aceptar</v-btn>
                    </div>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
/* global google */
// import axios from 'axios'
// TODO: Modify all snake_case to camelCase
export default {
    name: 'AddressDialog',
    props: {
        value: {
            Boolean,
            default: false
        },
        data: {
            Object,
            default: () => {
                return {
                    address: '',
                    locality: '',
                    city: '',
                    country: '',
                    countryCode: '',
                    postalCode: '',
                    latitude: '',
                    longitude: '',
                }
            }
        }
    },
    data() {
        return {
            isLoading: false,
            address: '',
            autocomplete: null,
            addressObject: {
                address: '',
                locality: '',
                city: '',
                country: '',
                countryCode: '',
                postalCode: '',
                latitude: '',
                longitude: '',
            }
        }
    },
    computed: {
        addressDialog: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
    },
    watch: {
        value(val) {
            if (val) {
                this.addressObject = { ...this.data }
                this.$nextTick(() => {
                    this.initAutocomplete()
                })
            }
        }
    },
    mounted() {
        if (!window.google) {
            let script = document.createElement('script')
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBe7qIMfwt7lnRscp8d77A2pW2a37kKzDc&libraries=places`;
            script.async = true
            script.defer = true
            document.head.appendChild(script)
        }
    },
    methods: {
        initAutocomplete() {
            if (!this.$refs.autocomplete) return;
            const input = this.$refs.autocomplete.$el.querySelector("input");
            this.autocomplete = new google.maps.places.Autocomplete(input);
            this.autocomplete.addListener("place_changed", this.fillInAddress);
        },
        fillInAddress() {
            let place = this.autocomplete.getPlace();

            if (place.geometry) {
                this.addressObject.latitude = place.geometry.location.lat()
                this.addressObject.longitude = place.geometry.location.lng()
            }

            this.addressObject.address = this.data.default;
            for (let i = 0; i < place.address_components.length; i++) {
                let addressType = place.address_components[i].types[0]
                let val = place.address_components[i].long_name

                switch (addressType) {
                    case 'street_number':
                        this.addressObject.address = `, ${val}`
                        break
                    case 'route':
                        this.addressObject.address = `${val} ${this.addressObject.address ?? ''}`.trim()
                        break
                    case 'locality':
                        this.addressObject.locality = val
                        break
                    case 'administrative_area_level_1':
                        this.addressObject.city = val
                        break
                    case 'country':
                        this.addressObject.country = val
                        this.addressObject.countryCode = place.address_components[i].short_name
                        break
                    case 'postal_code':
                        this.addressObject.postalCode = val;
                        break
                }
            }
        },
        close() {
            this.addressObject = { ...this.data }
            this.addressDialog = false
        },
        save() {
            this.$emit('save', this.addressObject)
            this.addressDialog = false
        }
    },
}

</script>
