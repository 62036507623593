<!-- TODO: No añadir botón de añadir categoría -->
<template>
    <div>
        <v-toolbar flat height="80" class="px-lg-4" style="z-index: 1;">
            <h2>
                Equipo
            </h2>
            <template v-slot:extension>
                <!-- <div> -->
                <v-text-field v-model="filter" outlined dense solo flat label="Buscar" prepend-inner-icon="mdi-magnify">
                </v-text-field>
                <!-- </div> -->
                <BlockingBadge :locked="!canAddEmployees()" variant="fab-button">
                    <v-btn fab color="primary" class="mt-n5" bottom right absolute
                        @click="$router.push('employees/new')" :disabled="!canAddEmployees()">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </BlockingBadge>
            </template>
        </v-toolbar>
        <v-container fluid class="pa-lg-8 pt-6">
            <v-card v-for="(employee, idx) in filteredEmployees" :key="`employee-${employee.id}`" outlined
                class="rounded-0" :class="idx == 0 ? 'rounded-t-lg' : idx == employees.length - 1 ? 'rounded-b-lg' : ''"
                :style="idx > 0 ? 'border-top: 0;' : ''">
                <v-card-text class="px-5 black--text d-flex flex-row align-center">
                    <v-avatar :size="$vuetify.breakpoint.mdAndDown ? 45 : 60" :color="employee.color" tile
                        class="rounded-xl mr-5 white--text" style="opacity: 0.7">
                        {{ employee.initials }}
                    </v-avatar>
                    <v-row>
                        <v-col cols="10" class="d-flex flex-column justify-center">
                            <span class="body-1 font-weight-bold grey--text text--darken-3">
                                {{ employee.fullName }}
                                <span class="ml-1 body-2 grey--text text--darken-1">({{
                                    getNumberOfServicesFromEmployee(employee) }})</span>
                            </span>
                            <div class="mt-1">
                                <v-chip label :small="!$vuetify.breakpoint.mdAndDown"
                                    :x-small="$vuetify.breakpoint.mdAndDown"
                                    :class="$vuetify.breakpoint.mdAndDown && 'ml-1 py-3'" outlined
                                    :color="`${service.color?.split(' ')[0]} lighten-2`" class="mr-1 mt-1"
                                    v-for="service in employee.services" :key="`service-${employee.id}-${service.id}`">
                                    <v-icon small left :color="service.color">mdi-tag</v-icon>
                                    <span :class="`${service.color?.split(' ')[0]}--text`">
                                        {{ service.name }}
                                    </span>
                                </v-chip>
                            </div>
                        </v-col>
                        <v-col cols="2" class="d-flex justify-end align-center">
                            <v-btn color="teal accent-4" class="font-weight-black" text
                                @click="$router.push(`employees/${employee.id}`)"
                                :small="$vuetify.breakpoint.mdAndDown">Editar</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>
        <DialogSubscriptionLimit v-model="showSubscriptionLimitDialog" :variant="SUBSCRIPTION_LIMIT_VARIANTS.Employees"
            :maxLimit="getFeatureValue(FEATURES.Employees)" :currentCount="currentEmployeesCount" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import BlockingBadge from '@/components/business/BlockingBadge.vue'
import DialogSubscriptionLimit from '@/components/business/DialogSubscriptionLimit.vue'
import { canAddEmployees, getFeatureValue, isFeatureLimited } from '@/utils'
import { FEATURES, SUBSCRIPTION_LIMIT_VARIANTS } from '@/consts'

export default {
    name: 'EmployeesView',
    components: {
        BlockingBadge,
        DialogSubscriptionLimit,
    },
    data() {
        return {
            FEATURES,
            SUBSCRIPTION_LIMIT_VARIANTS,
            filter: '',
            showSubscriptionLimitDialog: isFeatureLimited(FEATURES.Employees),
        }
    },
    computed: {
        ...mapGetters(['employees', 'services']),
        filteredEmployees() {
            return this.employees.filter(employee => employee.fullName.toLowerCase().includes(this.filter.toLowerCase()))
        },
        currentEmployeesCount() {
            return this.employees.length;
        }
    },
    created() {
        this.fetchEmployees();
        this.fetchServices();
    },
    methods: {
        ...mapActions(['fetchEmployees', 'fetchServices']),
        canAddEmployees,
        getFeatureValue,
        getNumberOfServicesFromEmployee(employee) {
            if (employee.services.length === this.services.length && employee.services.length > 0) return 'Todos los servicios';
            else if (employee.services.length === 0) return 'Ningún servicio';
            else return `${employee.services.length} servicios`;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
