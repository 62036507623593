<template>
    <v-dialog v-model="verifyEmailDialog" fullscreen transition="none">
        <v-img class="d-flex justify-center" :src="require('@/assets/img/login-background.png')"
            style="position: fixed; top: 0; left: 0; width: 100vw; height: 100vh;"></v-img>
        <v-card class="elevation-0" :class="!isMobile ? 'transparent' : 'white'">
            <v-toolbar flat color="transparent" style="position: sticky; top: 0;">
                <!-- <v-btn icon large @click="close">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn> -->
            </v-toolbar>
            <v-card class="elevation-0 elevation-md-2 px-8 mb-md-15 pb-6"
                :class="!$vuetify.breakpoint.mdAndDown && 'rounded-xl'" style="max-width: 600px; margin: auto;">
                <v-card-title class="mt-1 d-flex justify-center align-center">
                    <v-img max-height="40" contain class="ml-10 mt-5" :src="require('@/assets/logo-teal.png')"></v-img>
                </v-card-title>
                <v-card-text>
                    <p class="text-body-1 black--text mt-8 font-weight-bold">{{ alertMessage }}</p>
                    <p class="text-body-1 gray--text text-darken-4" v-if="verificationSuccess">Ya está todo listo, {{
                        email }} ya está verificado.</p>
                </v-card-text>
                <v-card-text>
                    <v-btn block class="mt-1 rounded-lg py-4 font-weight-bold" color="primary" :to="businessUrl"
                        v-if="verificationSuccess">
                        Entrar
                    </v-btn>
                    <v-btn block class="mt-1 rounded-lg py-4 font-weight-bold" color="primary" to="/login" v-else>
                        Iniciar sesión
                    </v-btn>
                </v-card-text>
            </v-card>
            <div class="d-flex align-center justify-center  gray--text text-darken-4 mt-n8">
                <v-btn text plain class="text-capitalize" :href="LANDING_URL" target="_blank">© Slotspot</v-btn>
                <span>·</span>
                <v-btn text plain class="text-capitalize" :href="`${LANDING_URL}/contact`"
                    target="_blank">Contacto</v-btn>
                <span>·</span>
                <v-btn text plain class="text-none" :href="`${LANDING_URL}/terms`" target="_blank">Términos y
                    condiciones</v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: 'VerifyEmailView',
    props: {
        token: String
    },
    data() {
        return {
            LANDING_URL: process.env.VUE_APP_LANDING_URL,
            urlServer: `${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}`,
            verifyEmailDialog: true,
            email: '',
            verificationSuccess: false,
            alertMessage: '',
            businessUrl: '/business'
        }
    },
    computed: {
        isMobile() {
            return window.innerWidth < 600
        },
    },
    async mounted() {
        // Fetch the user's email verification
        try {
            const response = await this.$store.dispatch('auth/verifyEmail', this.token)
            if (response.status === 200) {
                // this.log('El correo electrónico ya está verificado.')
                // console.log('El correo electrónico ya está verificado.')
                const { user, businessId } = response.data
                this.email = user.email
                this.businessUrl = `/businesses/${businessId}`
                this.alertMessage = 'El correo electrónico está verificado.'
                this.verificationSuccess = true
            } else {
                // this.log('El correo electrónico no se pudo verificar.')
                // console.log('El correo electrónico no se pudo verificar.')
                this.verificationSuccess = false
                this.alertMessage = 'El correo electrónico no se pudo verificar.'
            }
        } catch (error) {
            // this.log('El correo electrónico no se pudo verificar.')
            // console.log('El correo electrónico no se pudo verificar.')
            this.alertMessage = 'El correo electrónico no se pudo verificar.'
            this.verificationSuccess = false
        }
        this.$store.state.pendingRequests = 0
    },
    methods: {
        close() {
            this.verifyEmailDialog = false
            this.$nextTick(() => {
                this.$router.back()
            })
        },
    }
}
</script>
<style scoped>
a {
    letter-spacing: 0.2px;
}
</style>