<template>
    <div @click="handleClickBadge" style="cursor: pointer;" :class="`${variant}-badge ${blur ? 'blur' : ''}`">
        <v-badge bordered overlap color="error" icon="mdi-lock" style="width: 100%;" v-if="locked" :left="left">
            <slot />
        </v-badge>
        <slot v-else></slot>
    </div>
</template>
<script>
import { goesToChangePlan } from '@/utils';

const VARIANTS = {
    Default: 'default',
    FabButton: 'fab-button-badge',
    NavItem: 'nav-item',
    SmallButton: 'small-button',
    XSmallButton: 'x-small-button',
    TextButton: 'text-button',
};

export default {
    name: 'BlockingBadge',
    props: {
        locked: {
            type: Boolean,
            default: false,
        },
        blur: {
            type: Boolean,
            default: false,
        },
        left: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: VARIANTS.Default,
        },
    },
    data() {
        return {
            VARIANTS,
        };
    },
    methods: {
        handleClickBadge() {
            if (this.locked) {
                goesToChangePlan();
            }
        },
    },
    mounted() {
    }
};
</script>
<style>
.v-badge__badge {
    transform: translate(0, 0);
}

.fab-button-badge {
    &>span>span>.v-badge__badge {
        transform: translate(-80%, -100%) !important;
        z-index: 10;
    }
}

.nav-item-badge {
    &>span>span>.v-badge__badge {
        transform: translate(-80%, 100%) !important;
        z-index: 10;
    }
}

.small-button-badge {
    &>span>span>.v-badge__badge {
        transform: translate(-50%, 0%) !important;
        z-index: 10;
    }
}

.x-small-button-badge {
    &>span>span>.v-badge__badge {
        transform: translate(-30%, 30%) !important;
        z-index: 10;
    }
}

.text-button-badge {
    &>span>span>.v-badge__badge {
        transform: translate(50%, 50%) !important;
        z-index: 10;
    }
}

.blur {
    &>span>div {
        filter: blur(5px);
    }
}
</style>