<template>
    <div>
        <v-toolbar flat height="110" class="px-lg-4">
            <div class="d-flex flex-column">
                <v-toolbar-title class="text-h5 font-weight-bold black--text">
                    Enlaces para clientes
                </v-toolbar-title>
                <div class="font-weight-regular grey--text text--darken-2">
                    Dirige a tus clientes hacia tu plataforma de reservas.
                </div>
            </div>
        </v-toolbar>
        <v-container fluid class="pa-lg-8" v-if="ready">
            <v-card outlined class="rounded-lg">
                <v-card-text class="px-5 d-flex flex-column">
                    <v-row>
                        <v-col cols="12" sm="6" lg="5" xl="3" class="flex-lg-grow-0 flex-lg-shrink-0">
                            <v-card class="py-4 px-2 rounded-lg" outlined>
                                <v-card-title class="py-0 font-weight-bold">Código QR</v-card-title>
                                <BlockingBadge :locked="!isQRCodeVisible" blur>
                                    <v-card-text class="d-flex justify-center">
                                        <vue-qr :text="isQRCodeVisible ? businessUrlProfile : 'locked-business-qr'"
                                            qid="qr-id" :logoSrc="require('@/assets/logo-qr.png')"
                                            :logoCornerRadius="qrSize * 0.19" :dotScale="1" :size="qrSize"
                                            :logoScale="0.4" :callback="QRcallback" :correctLevel="3"></vue-qr>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn color="teal accent-4"
                                            class="py-3 font-weight-black elevation-0 rounded-lg"
                                            :dark="isQRCodeVisible" block :href="downloadUrl" download="qr-code.png"
                                            :disabled="!isQRCodeVisible">Descargar código
                                            QR</v-btn>
                                    </v-card-actions>
                                </BlockingBadge>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" lg="7" xl="9" class="flex-lg-grow-1 flex-lg-shrink-1">
                            <v-card class="py-4 px-3 rounded-lg" outlined>
                                <v-card-title class="pt-0 font-weight-bold">Redes sociales</v-card-title>
                                <v-card-text class="d-flex justify-center">
                                    <v-row>
                                        <v-col cols="6" md="4" xl="2" class="text-center font-weight-bold"
                                            v-for="social in socials" :key="social.title">
                                            <v-btn class="rounded-xl px-5 py-8" elevation="0" :class="social.bgColor"
                                                :href="social.url" target="_blank">
                                                <v-icon :color="social.color">{{ social.icon }}</v-icon>
                                            </v-btn>
                                            <div>{{ social.title }}</div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-title class="font-weight-bold">URL del sitio web de reservas</v-card-title>
                                <v-card-actions>
                                    <v-text-field outlined dense class="rounded-lg" :value="businessUrlProfile">
                                        <template v-slot:append>
                                            <v-chip v-if="copySuccessful" small label dark
                                                class="rounded-lg mr-3 teal accent-4">¡Copiado!</v-chip>
                                            <v-btn icon small @click="copyToClipboard">
                                                <v-icon dense class="mt-n1">mdi-content-copy</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                    <!-- <v-text-field style="opacity: 0; z-index: -10;"
                                        v-if="!isQRCodeVisible"></v-text-field> -->
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import BlockingBadge from '@/components/business/BlockingBadge.vue'
import VueQr from 'vue-qr'
import { mapGetters, mapActions } from 'vuex'
import { isFeatureAvailable } from '@/utils'
import { FEATURES } from '@/consts'

export default {
    name: 'LinksView',
    components: {
        VueQr,
        BlockingBadge
    },
    data() {
        return {
            ready: false,
            copySuccessful: false,
            downloadUrl: null,
            qrSize: 320,
        }
    },
    computed: {
        ...mapGetters(['business']),
        isQRCodeVisible() {
            return isFeatureAvailable(FEATURES.BussinessQR)
        },
        businessUrlProfile() {
            return `${process.env.VUE_APP_URL_CLIENT}/${this.business.subdomain}`
        },
        socials() {
            return [
                {
                    title: "SMS",
                    icon: "mdi-message-text",
                    color: "teal",
                    bgColor: "teal lighten-5",
                    url: `sms:?&body=${encodeURIComponent(`¡Es hora de garantizar tu espacio en ${this.business.name}! Descubre una nueva forma de reservar - rápida y sin complicaciones.`)}%20${encodeURIComponent(this.businessUrlProfile)}`
                },
                {
                    title: "Email",
                    icon: "mdi-email",
                    color: "red",
                    bgColor: "red lighten-5",
                    url: `mailto:?subject=${encodeURIComponent(`🎉 ¡Es hora de vivir la experiencia ${this.business.name}!`)}&body=${encodeURIComponent(`¡Hola!\nTenemos el placer de invitarte a explorar nuestra innovadora página de reservas. Reservar en línea nunca ha sido tan sencillo y divertido. ✨ ¡Estamos convencidos de que te va a encantar! Para iniciar tu experiencia, solo tienes que seguir el enlace: ${this.businessUrlProfile}\n\n¡Estamos impacientes por verte pronto! 🙌\n\nUn saludo,\nEl equipo de ${this.business.name}`)}`
                },
                {
                    title: "Facebook",
                    icon: "mdi-facebook",
                    color: "blue darken-2",
                    bgColor: "blue lighten-5",
                    url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.businessUrlProfile)}`
                },
                {
                    title: "Twitter",
                    icon: "mdi-twitter",
                    color: "blue lighten-2",
                    bgColor: "blue lighten-5",
                    url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.businessUrlProfile)}&text=${encodeURIComponent(`¡Asegura tu espacio con ${this.business.name} hoy! 🙌 Descubre la facilidad de reservar online. 💻 #reserva #online`)}`
                },
                {
                    title: "Whatsapp",
                    icon: "mdi-whatsapp",
                    color: "green darken-1",
                    bgColor: "green lighten-5",
                    url: `https://wa.me/?text=${encodeURIComponent(`¡Es tiempo de asegurar tu espacio en ${this.business.name}! Prueba nuestra plataforma de reservas, sencilla y eficaz.`)}%20${encodeURIComponent(this.businessUrlProfile)}`
                },
                {
                    title: "LinkedIn",
                    icon: "mdi-linkedin",
                    color: "blue darken-3",
                    bgColor: "blue lighten-5",
                    url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(this.businessUrlProfile)}`
                }
            ]
        }

    },
    async created() {
        if (!this.business?.id) {
            await this.fetchBusiness()
        }
        this.ready = true
    },
    methods: {
        ...mapActions(['fetchBusiness']),
        async copyToClipboard() {
            try {
                await navigator.clipboard.writeText(this.businessUrlProfile);
                this.copySuccessful = true;

                // After 2 seconds, remove the "Copied!" message
                setTimeout(() => {
                    this.copySuccessful = false;
                }, 2000);
            } catch (err) {
                console.error('Error al copiar al portapapeles', err);
            }
        },
        QRcallback(dataURL) {
            this.downloadUrl = dataURL;
        },
        downloadQR() {
            const canvas = this.$refs.qr.$el.querySelector("canvas"); // get the canvas element
            const link = document.createElement("a"); // create an anchor link
            link.download = "qr-code.png"; // set the download attribute to the link
            link.href = canvas.toDataURL(); // set the href attribute to the canvas data URL
            link.click(); // simulate a click event on the link
        },
    }
}
</script>