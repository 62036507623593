<template>
    <NavigationDrawer v-model="drawerValue" v-if="$vuetify.breakpoint.mdAndDown" :items="navigationItems"
        :showUpdatePlanButton="true">
    </NavigationDrawer>
</template>

<script>
import NavigationDrawer from '@/components/business/NavigationDrawer.vue'
import { navigationItems } from '@/utils'

export default {
    name: 'MobileNavigationDrawer',
    components: {
        NavigationDrawer,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
        }
    },
    computed: {
        navigationItems,
        drawerValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
