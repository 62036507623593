export const SUBSCRIPTION_PLANS = {
    BASIC: 'free',
    STANDARD: 'standard',
    PREMIUM: 'premium',
}

export const SUBSCRIPTION_PERIODS = {
    MONTHLY: 'monthly',
    ANNUAL: 'annual',
}

export const SUBSCRIPTION_STATUSES = {
    ACTIVE: 'active',
    CANCELLED: 'cancelled',
}

export const BOOKING_NOTIFICATION_TYPE = {
    REJECTED: 'rejected',
    CANCELLED: 'cancelled',
    REMINDER: 'reminder',
    CONFIRMED: 'confirmed',
    RESCHEDULED: 'rescheduled',
    PENDING: 'pending',
    REVIEW: 'review',
}

export const BOOKING_STATUSES = {
    PENDING: 0,
    CONFIRMED: 1,
    CANCELLED: 2,
    CLIENT_ABSENT: 3,
    CLIENT_ARRIVED: 4,
};

export const BOOKING_ORIGINS = {
    BOOKING_PAGE: 'web',
    CALENDAR: 'calendar',
};

export const NOTIFICATION_METHODS = {
    EMAIL: 'email',
    SMS: 'sms',
    WHATSAPP: 'whatsapp',
};

export const FEATURES = {
    MonthlyAppointments: 'MonthlyAppointments',
    TotalClients: 'TotalClients',
    Services: 'Services',
    Employees: 'Employees',
    BillingModule: 'BillingModule',
    BusinessSplitSchedule: 'BusinessSplitSchedule',
    NotificationMethodsAvailable: 'NotificationMethodsAvailable',
    NotificationTypesAvailable: 'NotificationTypesAvailable',
    ClientsHistory: 'ClientsHistory',
    ClientsTags: 'ClientsTags',
    ClientsNotes: 'ClientsNotes',
    BussinessQR: 'BussinessQR',
    Vacations: 'Vacations',
    ClientsBlacklist: 'ClientsBlacklist',
    BusinessProfileCustomization: 'BusinessProfileCustomization',
    ClientsAdvancedProfile: 'ClientsAdvancedProfile',
    BusinessAnalytics: 'BusinessAnalytics',
    ClientsExport: 'ClientsExport',
    CustomSubdomain: 'CustomSubdomain',
    CustomCancelationPolicies: 'CustomCancelationPolicies',
    RemoveSlotspotBranding: 'RemoveSlotspotBranding',
}

export const FEATURES_BY_PLAN = {
    [SUBSCRIPTION_PLANS.BASIC]: {
        [FEATURES.MonthlyAppointments]: 20,
        [FEATURES.TotalClients]: 50,
        [FEATURES.Services]: 1,
        [FEATURES.Employees]: 1,
        [FEATURES.BillingModule]: false,
        [FEATURES.BusinessSplitSchedule]: false,
        [FEATURES.NotificationMethodsAvailable]: [NOTIFICATION_METHODS.EMAIL],
        [FEATURES.NotificationTypesAvailable]: [
            BOOKING_NOTIFICATION_TYPE.REJECTED,
            BOOKING_NOTIFICATION_TYPE.CANCELLED,
            BOOKING_NOTIFICATION_TYPE.RESCHEDULED,
            BOOKING_NOTIFICATION_TYPE.CONFIRMED,
        ]
    },
    [SUBSCRIPTION_PLANS.STANDARD]: {
        [FEATURES.MonthlyAppointments]: 600,
        [FEATURES.TotalClients]: 0,
        [FEATURES.Services]: 5,
        [FEATURES.Employees]: 3,
        [FEATURES.BillingModule]: true,
        [FEATURES.BusinessSplitSchedule]: true,
        [FEATURES.NotificationMethodsAvailable]: [NOTIFICATION_METHODS.EMAIL, NOTIFICATION_METHODS.SMS, NOTIFICATION_METHODS.WHATSAPP],
        [FEATURES.NotificationTypesAvailable]: [
            BOOKING_NOTIFICATION_TYPE.REJECTED,
            BOOKING_NOTIFICATION_TYPE.CANCELLED,
            BOOKING_NOTIFICATION_TYPE.REMINDER,
            BOOKING_NOTIFICATION_TYPE.CONFIRMED,
            BOOKING_NOTIFICATION_TYPE.RESCHEDULED,
            BOOKING_NOTIFICATION_TYPE.PENDING,
        ],
        [FEATURES.ClientsHistory]: true,
        [FEATURES.ClientsTags]: true,
        [FEATURES.ClientsNotes]: true,
        [FEATURES.BussinessQR]: true,
        [FEATURES.Vacations]: true,
        [FEATURES.ClientsBlacklist]: true,
        [FEATURES.BusinessProfileCustomization]: true,
    },
    [SUBSCRIPTION_PLANS.PREMIUM]: {
        [FEATURES.MonthlyAppointments]: 0,
        [FEATURES.TotalClients]: 0,
        [FEATURES.Services]: 0,
        [FEATURES.Employees]: 0,
        [FEATURES.BillingModule]: true,
        [FEATURES.BusinessSplitSchedule]: true,
        [FEATURES.NotificationMethodsAvailable]: [NOTIFICATION_METHODS.EMAIL, NOTIFICATION_METHODS.SMS, NOTIFICATION_METHODS.WHATSAPP],
        [FEATURES.NotificationTypesAvailable]: [
            BOOKING_NOTIFICATION_TYPE.REJECTED,
            BOOKING_NOTIFICATION_TYPE.CANCELLED,
            BOOKING_NOTIFICATION_TYPE.REMINDER,
            BOOKING_NOTIFICATION_TYPE.CONFIRMED,
            BOOKING_NOTIFICATION_TYPE.RESCHEDULED,
            BOOKING_NOTIFICATION_TYPE.PENDING,
            BOOKING_NOTIFICATION_TYPE.REVIEW,
        ],
        [FEATURES.ClientsHistory]: true,
        [FEATURES.ClientsTags]: true,
        [FEATURES.ClientsNotes]: true,
        [FEATURES.BussinessQR]: true,
        [FEATURES.Vacations]: true,
        [FEATURES.ClientsBlacklist]: true,
        [FEATURES.BusinessProfileCustomization]: true,
        [FEATURES.ClientsAdvancedProfile]: true,
        [FEATURES.BusinessAnalytics]: true,
        [FEATURES.ClientsExport]: true,
        [FEATURES.CustomSubdomain]: true,
        [FEATURES.CustomCancelationPolicies]: true,
        [FEATURES.RemoveSlotspotBranding]: true,
    },
};

export const SUBSCRIPTION_LIMIT_VARIANTS = {
    Appointments: 'appointments',
    Services: 'services',
    Clients: 'clients',
    Employees: 'employees',
}