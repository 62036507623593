<template>
    <div v-if="maxLimit > 0">
        <v-hover v-slot="{ hover }" v-if="!mini">
            <v-btn class="body-2 font-weight-bold elevation-0 rounded-lg mt-3 pr-2" :class="{ 'primary': hover }" block
                style="height: 100%;" @click="showSubscriptionLimitDialog = true">
                <div outlined class="d-flex flex-column px-2 py-4 rounded-lg" style="width: 100%;">
                    <span class="pl-2" v-if="hover">Aumentar límite</span>
                    <span class="pl-2" v-else>{{ title }}</span>
                    <div class="d-flex flex-row justify-center align-center pt-3" style="width: 100%;">
                        <v-icon class="pr-2" small v-if="usagePercentage < 100 || hover">mdi-lock-open-variant</v-icon>
                        <v-icon class="pr-2" small v-else>mdi-lock</v-icon>
                        <v-progress-linear style="width: 100%;" height="6" :color="!hover ? accentColor : 'white'"
                            :value="!hover ? usagePercentage : 100">
                        </v-progress-linear>
                        <span class="pl-2 caption" v-if="!hover">{{ currentCount }}/{{
                            maxLimit }}</span>
                        <v-icon dense class="pl-2" v-else>mdi-infinity</v-icon>
                    </div>
                </div>
            </v-btn>
        </v-hover>
        <v-btn v-else class="text-subtitle-1 d-flex justify-end align-center flex-column" icon
            @click="showSubscriptionLimitDialog = true">
            <v-progress-circular :value="usagePercentage" :color="accentColor" rotate="-90">
                <v-icon small v-if="usagePercentage < 100">mdi-lock-open-variant</v-icon>
                <v-icon small v-else>mdi-lock</v-icon>
            </v-progress-circular>
            <!-- <span>599/600</span> -->
        </v-btn>
        <DialogSubscriptionLimit v-model="showSubscriptionLimitDialog" :variant="SUBSCRIPTION_LIMIT_VARIANTS.Clients"
            :maxLimit="getFeatureValue(FEATURES.TotalClients)" :currentCount="currentClientsCount"
            v-if="variant === VARIANTS.Clients" />
        <DialogSubscriptionLimit v-model="showSubscriptionLimitDialog"
            :variant="SUBSCRIPTION_LIMIT_VARIANTS.Appointments"
            :maxLimit="getFeatureValue(FEATURES.MonthlyAppointments)" :currentCount="currentAppointmentsCount"
            v-if="variant === VARIANTS.Appointments" />
    </div>
</template>
<script>
import { FEATURES, SUBSCRIPTION_LIMIT_VARIANTS, SUBSCRIPTION_PLANS } from '@/consts';
import { getFeatureValue, isFeatureLimited } from '@/utils';
import { mapGetters } from 'vuex';

import DialogSubscriptionLimit from '@/components/business/DialogSubscriptionLimit.vue';

const VARIANTS = {
    Clients: "Clients",
    Appointments: "Appointments",
};

export default {
    name: 'ButtonSubscriptionLimit',
    props: {
        variant: {
            type: String,
            required: true,
        },
        mini: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        DialogSubscriptionLimit,
    },
    data() {
        return {
            FEATURES,
            VARIANTS,
            SUBSCRIPTION_PLANS,
            SUBSCRIPTION_LIMIT_VARIANTS,
            showSubscriptionLimitDialog: isFeatureLimited(this.variant),
        }
    },
    computed: {
        ...mapGetters(['currentClientsCount', 'currentAppointmentsCount']),
        accentColor() {
            return this.usagePercentage < 100 ? 'teal accent-4' : 'red';
        },
        title() {
            return this.variant === VARIANTS.Clients ? 'Clientes totales' : 'Reservas al mes';
        },
        currentCount() {
            return this.variant === VARIANTS.Clients ? this.currentClientsCount : this.currentAppointmentsCount;
        },
        maxLimit() {
            return this.variant === VARIANTS.Clients ? getFeatureValue(FEATURES.TotalClients) : getFeatureValue(FEATURES.MonthlyAppointments);
        },
        usagePercentage() {
            return (this.currentCount / this.maxLimit) * 100;
        },
    },
    methods: {
        getFeatureValue,
    },
    mounted() {
    },
}
</script>