<template>
    <div>
        <v-row class="pt-2">
            <v-col cols="12" class="pa-1">
                <v-card outlined>
                    <v-card-text class="black--text px-6 py-4">
                        <h2>
                            {{ clientId == 'new' ? 'Nuevo cliente' : 'Editar cliente' }}
                        </h2>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text class="black--text px-6 pb-0">
                        <h4 class="black--text">Nombre del cliente</h4>
                        <v-text-field v-model="client.fullName" label="Nombre" outlined solo flat dense
                            class="mt-2"></v-text-field>
                        <h4 class="black--text">Correo electrónico</h4>
                        <v-text-field v-model="client.email" label="Email" outlined solo flat dense
                            class="mt-2"></v-text-field>
                        <h4 class="black--text">Teléfono</h4>
                        <!-- <v-text-field v-model="client.phoneNumber" label="Teléfono" outlined solo flat dense class="mt-2"></v-text-field> -->
                        <PhoneInput v-model="client.phoneNumber" :phoneIso2.sync="client.phoneIso2" />
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text class="black--text px-6 pb-0">
                        <h4 class="black--text">Dirección</h4>
                        <BlockingBadge :locked="!isClientsAdvancedProfile" blur>
                            <v-textarea v-model="client.address" placeholder="Dirección" outlined solo flat dense
                                class="mt-2"></v-textarea>
                        </BlockingBadge>
                        <h4 class="black--text">Fecha de nacimiento</h4>
                        <BlockingBadge :locked="!isClientsAdvancedProfile" blur>
                            <v-row class="pt-2">
                                <v-col cols="4" md="2">
                                    <v-autocomplete v-model="dayBirthday" label="Día" outlined solo flat dense
                                        :items="Array.from({ length: 32 }).map((v, idx) => idx == 0 ? { value: null, text: '--' } : { value: idx, text: idx })">
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="8" md="5">
                                    <v-autocomplete v-model="monthBirthday" label="Mes" outlined solo flat dense
                                        :items="Array.from({ length: 13 }).map((v, idx) => idx == 0 ? { value: null, text: '--' } : { value: idx, text: getMonthName(idx, capitalize = true) })">
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </BlockingBadge>
                        <h4 class="black--text">Género</h4>
                        <BlockingBadge :locked="!isClientsAdvancedProfile" blur>
                            <v-autocomplete v-model="client.gender" label="Género" outlined solo flat dense
                                :items="genders" class="pt-2">
                            </v-autocomplete>
                        </BlockingBadge>
                        <!-- <h4 class="black--text">Nota</h4>
                            <v-textarea v-model="client.notes" placeholder="Nota" outlined solo flat class="mt-2"></v-textarea> -->
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text class="black--text px-6 pt-1 pb-5">
                        <div class="d-flex flex-row align-center">
                            <BlockingBadge :locked="!isClientsAdvancedProfile" blur>
                                <v-checkbox v-model="client.hasAcceptedPrivacyPolicy" color="primary" class="pt-1"
                                    hide-details></v-checkbox>
                            </BlockingBadge>
                            <span class="pt-5">Ha aceptado su política de privacidad</span>
                        </div>
                        <div class="d-flex flex-row align-center">
                            <BlockingBadge :locked="!isClientsAdvancedProfile" blur>
                                <v-checkbox v-model="client.hasAcceptedMarketingPolicy" color="primary" class="pt-1"
                                    hide-details></v-checkbox>
                            </BlockingBadge>
                            <span class="pt-5">El cliente quiere recibir alertas de marketing</span>
                        </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="px-6">
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="$router.back()" small class="py-5 px-6 rounded-lg">
                            Cancelar
                        </v-btn>
                        <v-btn color="primary" class="py-5 px-6 rounded-lg" small :text="!hasChanged"
                            :disabled="!hasChanged" @click="save">
                            <!-- <v-icon left>mdi-content-save</v-icon> -->
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import { isFeatureAvailable } from '@/utils'
import { FEATURES } from '@/consts'

import BlockingBadge from '@/components/business/BlockingBadge.vue'
import PhoneInput from '@/components/common/PhoneInput.vue'

// TODO: Automate clients details view
export default {
    name: 'FormView',
    components: {
        PhoneInput,
        BlockingBadge
    },
    props: {
        clientId: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            hasChanged: false,
            genders: [
                {
                    value: 'man',
                    text: 'Hombre'
                },
                {
                    value: 'women',
                    text: 'Mujer'
                },
                {
                    value: 'unknown',
                    text: 'Desconocido'
                }
            ],
            client: {},
            defaultClient: "",
            dayBirthday: null,
            monthBirthday: null
        }
    },
    computed: {
        ...mapGetters(['colors']),
        serializedClient() {
            return JSON.stringify(this.client)
        },
        isClientsAdvancedProfile() {
            return isFeatureAvailable(FEATURES.ClientsAdvancedProfile)
        },
    },
    watch: {
        serializedClient() {
            this.hasChanged = this.serializedClient !== this.defaultClient && this.defaultClient !== ""
        },
        dayBirthday() {
            if (this.dayBirthday && this.monthBirthday) {
                this.client.birthday = moment(`${this.dayBirthday}/${this.monthBirthday}`, 'DD/MM')
            }
        },
        monthBirthday() {
            if (this.dayBirthday && this.monthBirthday) {
                this.client.birthday = moment(`${this.dayBirthday}/${this.monthBirthday}`, 'DD/MM')
            }
        }
    },
    async created() {
        if (this.clientId == 'new') {
            this.client.color = this.colors[Math.floor(Math.random() * this.colors.length)].value
            this.defaultClient = JSON.stringify(this.client)
        } else {
            const client = await this.fetchClient(this.clientId)
            this.client = client
            this.dayBirthday = this.client.birthday ? parseInt(moment(this.client.birthday).format('DD')) : null
            this.monthBirthday = this.client.birthday ? parseInt(moment(this.client.birthday).format('MM')) : null
            this.$nextTick(() => {
                this.defaultClient = JSON.stringify(this.client)
            })
        }
    },
    mounted() {

    },
    methods: {
        ...mapActions(['fetchClient', 'createClient', 'updateClient']),
        getMonthName(number, capitalize = false) {
            if (number < 1 || number > 12) {
                throw new Error('Invalid month number');
            }
            if (capitalize) {
                return moment().locale('es').month(number - 1).format('MMMM').charAt(0).toUpperCase() + moment().locale('es').month(number - 1).format('MMMM').slice(1);
            }
            return moment().locale('es').month(number - 1).format('MMMM');
        },
        async save() {
            if (this.clientId == 'new') {
                const newClient = await this.createClient(this.client)
                this.$router.push(`/businesses/${localStorage.getItem('businessId')}/clients/details/${newClient.id}`)
            } else {
                await this.updateClient(this.client)
                this.$router.back()
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
