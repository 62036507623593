<template>
    <v-dialog v-model="dialog" persistent max-width="600" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
            <v-card-title class="headline">
                <v-img :src="require('@/assets/logo-teal.png')" :max-height="$vuetify.breakpoint.mdAndUp ? 60 : 35"
                    :max-width="$vuetify.breakpoint.mdAndUp ? 250 : 200" contain @click="$router.push('/')"
                    style="cursor: pointer;"></v-img>
                <v-spacer></v-spacer>
                <v-btn color="teal accent-4" class="mr-3 pa-3 px-5 font-weight-bold d-none d-md-flex elevation-0"
                    @click="handleClickClose" dark>
                    Cerrar
                </v-btn>
                <v-btn icon @click="handleClickClose" class="d-md-none">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-container class="pt-8 pt-md-0 pb-15 pa-0 d-flex justify-center flex-column" fluid>
                    <h3 style="font-size: 25px; line-height: 30px;" class="text-center mb-10 font-weight-light">Potencia
                        tu negocio con
                        notificaciones
                        personalizadas</h3>
                    <h1 style="font-size: 45px; line-height: 45px;" class="text-center font-weight-bold">Selecciona el
                        tipo de crédito que
                        necesitas</h1>
                    <p style="font-size: 20px; line-height: 25px;" class="text-center text-muted mt-10">Asegura una
                        comunicación fluida con
                        tus clientes eligiendo
                        entre SMS y WhatsApp
                    </p>

                    <!-- Selector SMS o WhatsApp -->
                    <div class="py-8 d-flex justify-center">
                        <v-btn large class="elevation-0 rounded-lg rounded-r-0"
                            :color="creditType === 'SMS' ? 'teal accent-4' : ''" :dark="creditType === 'SMS'"
                            @click="creditType = 'SMS'">
                            <v-icon left>mdi-message-text-outline</v-icon>
                            SMS</v-btn>
                        <v-btn large class="elevation-0 rounded-lg rounded-l-0"
                            :color="creditType === 'WHATSAPP' ? 'teal accent-4' : ''" :dark="creditType === 'WHATSAPP'"
                            @click="creditType = 'WHATSAPP'">
                            <v-icon left>mdi-whatsapp</v-icon>
                            WhatsApp</v-btn>
                    </div>

                    <p class="text-center text-muted mt-2 d-none d-md-block">Elige la cantidad de créditos que mejor se
                        adapte a tu
                        volumen de mensajes y optimiza la comunicación con tus clientes.</p>


                    <v-row class="d-flex justify-center mb-6">
                        <v-col cols="12" md="6" lg="3" v-for="pack in creditPackages[creditType]" :key="pack.quantity">
                            <v-card outlined height="100%" class="rounded-lg d-flex flex-column">
                                <v-card-title class="pa-8 pa-md-10 d-flex justify-center flex-column text-center">
                                    <h2 class="teal--text text--accent-4 text-break"
                                        :style="`font-size: ${$vuetify.breakpoint.mdAndUp ? 45 : 35}px;`">{{
                                            pack.quantity }}
                                        {{ creditTypeTexts[creditType] }}s</h2>
                                    <h1 class="mt-6"> {{ pack.price.toFixed(2).replace('.',
                                        ',') }}<span class="text-h6"> €
                                            *</span></h1>
                                    <p
                                        class="text-muted text-center text-break mt-2 text-body-1 grey--text text--darken-2">
                                        <span class="text-h6">Cada {{ creditTypeTexts[creditType] }} a {{ (pack.price /
                                            pack.quantity).toFixed(3).replace('.',
                                                ',') }}€</span>
                                    </p>
                                    <v-btn large color="primary" class="px-6 py-5 rounded-lg mt-4 mt-md-10"
                                        @click="purchaseCredits(pack)">Comprar</v-btn>
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>

                    <p class="text-center text-muted">Los créditos no caducan y puedes usarlos cuando los
                        necesites. ¡Invierte en una mejor comunicación para tu negocio!</p>
                    <p class="text-center text-muted">* Todos los precios están exentos de IVA.</p>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import api from '@/services/api';

export default {
    name: 'SmsWhatsappPurchaseDialog',
    props: {
        value: Boolean
    },
    data() {
        return {
            // dialog: this.value,
            dialog: true,
            creditType: 'SMS',
            creditPackages: {
                SMS: [
                    { quantity: 100, price: 10 },
                    { quantity: 200, price: 18 },
                    { quantity: 500, price: 40 },
                    { quantity: 1000, price: 75 }
                ],
                WHATSAPP: [
                    { quantity: 100, price: 5 },
                    { quantity: 200, price: 9 },
                    { quantity: 500, price: 20 },
                    { quantity: 1000, price: 35 }
                ]
            },
            creditTypeTexts: {
                SMS: 'SMS',
                WHATSAPP: 'WhatsApp'
            }
        };
    },
    watch: {
        value(val) {
            this.dialog = val;
        },
        dialog(val) {
            this.$emit('input', val);
        }
    },
    methods: {
        async purchaseCredits(pack) {
            try {
                const response = await api.post('/subscriptions/buy-credits', {
                    businessId: this.$store.getters.business.id,
                    type: this.creditType,
                    quantity: pack.quantity
                });
                // window.location = response.data.clientSecret;
                console.log('response:', response.data.url);
                const url = response.data.url;
                window.location = url;
            } catch (error) {
                console.error('Error en la compra:', error);
            }
        },
        handleClickClose() {
            const businessId = this.$route.params.businessId;
            this.$router.push(`/businesses/${businessId}/settings/notifications`);
        }
    }
};
</script>

<style scoped></style>
