<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>

<style>
@font-face {
  font-family: "PlusJakartaSans";
  src: url("@/assets/fonts/PlusJakartaSans.ttf");
}

.v-application {
  font-family: 'PlusJakartaSans', 'Quicksand', 'Gotham', 'Avenir', Helvetica, Arial, sans-serif !important;
  font-weight: bold !important;
}

#app .translucient-white {
  backdrop-filter: blur(20px);
  background: rgba(255, 255, 255, 0.8);
}

#app .translucient-teal {
  backdrop-filter: blur(20px);
  background: rgba(158, 250, 241, 0.7);
}

#app .translucient-accent-teal {
  backdrop-filter: blur(20px);
  background: rgba(158, 250, 241, 0.5);
}

#app .translucient-dark {
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.4);
}
</style>