<template>
  <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition" scrollable persistent>
    <v-card color="grey lighten-4" flat>
      <!-- Toolbar del wizard -->
      <v-card-title class="white pb-0 pb-md-4">
        <!-- <v-toolbar flat color="primary" dark> -->
        <v-toolbar-title>Asistente de configuración</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelWizard" class="d-none d-md-block">
          Saltar
          <v-icon right>mdi-close</v-icon>
        </v-btn>
        <v-btn icon @click="cancelWizard" class="d-md-none">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!-- </v-toolbar> -->
      </v-card-title>
      <v-card-text class="pa-0">
        <v-card style="max-width: 900px; margin: auto;">

          <!-- Contenido del wizard con v-stepper vertical -->
          <v-stepper v-model="step" :alt-labels="$vuetify.breakpoint.mdAndUp" class="stepper">
            <v-stepper-header class="sticky">
              <v-stepper-step step="1" :complete="step > 1">
                <div style="min-width: 200px;" class="text-center">Perfil de tu negocio</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="2" :complete="step > 2">
                <div style="min-width: 200px;" class="text-center">Tus servicios</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="3">
                <div style="min-width: 200px;" class="text-center">Tus empleados</div>
              </v-stepper-step>
            </v-stepper-header>
            <!-- Paso 1: Perfil de tu negocio -->
            <v-stepper-items class="px-md-4">
              <v-stepper-content step="1">
                <h2 v-if="!$vuetify.breakpoint.mdAndUp">Perfil de tu negocio</h2>
                <v-divider class="mb-4 mt-2" v-if="!$vuetify.breakpoint.mdAndUp"></v-divider>
                <v-form ref="businessForm">
                  <v-card-text style="max-width: 600px;">
                    <h4 class="black--text mb-2">Nombre de la empresa</h4>
                    <v-text-field v-model="business.name" outlined required dense></v-text-field>
                    <h4 class="black--text mb-2">Teléfono</h4>
                    <PhoneInput v-model="business.phoneNumber" :phoneIso2.sync="business.phoneIso2"
                      :phoneCode.sync="business.phoneCode" />
                    <h4 class="black--text mb-2">Moneda de pago</h4>
                    <CurrencyInput v-model="business.currencyCode" />
                    <h4 class="black--text mb-2">Dirección física del negocio</h4>
                    <!-- <v-text-field v-model="business.address" label="Dirección del negocio" outlined required
                  dense></v-text-field> -->
                    <v-card outlined class="mb-8 rounded-lg">
                      <v-row>
                        <v-col cols="12">
                          <v-card-text v-if="!addressObject.country">
                            No se ha añadido ninguna dirección. Haz clic para añadir una nueva dirección para rellenar
                            tu
                            dirección
                            comercial.
                          </v-card-text>
                          <v-card-text v-else class="pb-0">
                            <v-card class="pa-5 elevation-0 pb-0">
                              <v-row v-if="addressObject.address !== ''">
                                <v-col cols="6" md="3">
                                  <span class="font-weight-bold">Calle</span>
                                </v-col>
                                <v-col cols="6" md="9">
                                  <span class="body-1">{{ addressObject.address }}</span>
                                </v-col>
                              </v-row>
                              <v-row v-if="addressObject.locality !== ''">
                                <v-col cols="6" md="3">
                                  <span class="font-weight-bold">Localidad</span>
                                </v-col>
                                <v-col cols="6" md="9">
                                  <span class="body-1">{{ addressObject.locality }}</span>
                                </v-col>
                              </v-row>
                              <v-row v-if="addressObject.city !== ''">
                                <v-col cols="6" md="3">
                                  <span class="font-weight-bold">Ciudad</span>
                                </v-col>
                                <v-col cols="6" md="9">
                                  <span class="body-1">{{ addressObject.city }}</span>
                                </v-col>
                              </v-row>
                              <v-row v-if="addressObject.country !== ''">
                                <v-col cols="6" md="3">
                                  <span class="font-weight-bold">País</span>
                                </v-col>
                                <v-col cols="6" md="9">
                                  <span class="body-1">{{ addressObject.country }}</span>
                                  <span class="fi ml-2"
                                    :class="`fi-${addressObject.countryCode?.toLowerCase()}`"></span>
                                </v-col>
                              </v-row>
                              <v-row v-if="addressObject.postalCode !== ''">
                                <v-col cols="6" md="3">
                                  <span class="font-weight-bold">Código Postal</span>
                                </v-col>
                                <v-col cols="6" md="9">
                                  <span class="body-1">{{ addressObject.postalCode }}</span>
                                </v-col>
                              </v-row>
                              <v-row v-if="addressObject.latitude > 0">
                                <v-col cols="6" md="3">
                                  <span class="font-weight-bold">Ubicación</span>
                                </v-col>
                                <v-col cols="6" md="9">
                                  <span class="body-1">
                                    {{ addressObject.latitude ? `${addressObject.latitude}ºN` : '' }}
                                    {{ addressObject.longitude ? `, ${addressObject.longitude}ºW` : '' }}
                                  </span>
                                </v-col>
                              </v-row>
                              <!-- TODO: Incluir mapa -->
                            </v-card>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" class="font-weight-bold mx-4 my-3" @click="addressDialog = true">{{
                              !addressObject.country ? 'Agregar' : 'Modificar' }}</v-btn>
                          </v-card-actions>
                        </v-col>
                      </v-row>
                    </v-card>
                    <AddressDialog v-model="addressDialog" :data="addressObject" @save="handleSaveAddress" />
                    <h4 class="black--text mb-2">Horario laboral</h4>
                    <v-card outlined class="rounded-lg mb-5">
                      <v-card-text class="pa-6 pb-0">
                        <v-row v-for="day in business.schedule" :key="`weekday-${day.day}`" class="py-1">
                          <v-col cols="12" md="3" class="py-0">
                            <span class="font-weight-bold body-2 text-capitalize">{{ getWeekDay(day.day) }}</span>
                          </v-col>
                          <v-col cols="12" md="3" class="d-flex flex-column justify-center text-center py-0">
                            <span v-if="!day.isOpen" class="body-2 font-weight-regular">
                              Cerrado
                            </span>
                            <template v-else>
                              <span v-for="(item, idx) in day.opening" :key="`tt-opening-${day.day}-${idx}`"
                                class="body-1 font-weight-regular">
                                {{ item.open }} - {{ item.close }}
                              </span>
                            </template>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" class="font-weight-bold mx-4 my-3"
                          @click="scheduleDialog = true">Modificar</v-btn>
                      </v-card-actions>
                    </v-card>
                    <ScheduleDialog v-model="scheduleDialog" :schedule.sync="business.schedule" />
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="pt-4">
                  <v-spacer></v-spacer>
                  <v-btn text @click="cancelWizard" class="px-6">Cancelar</v-btn>
                  <v-btn color="primary" :disabled="!validBusiness" @click="handleClickSaveBusinessDetailsAndContinue"
                    class="px-6">
                    Continuar
                  </v-btn>
                </v-card-actions>
              </v-stepper-content>

              <!-- Paso 2: Tus servicios -->
              <v-stepper-content step="2">
                <v-card-text class="px-0 px-md-4">
                  <v-form ref="servicesForm" lazy-validation>
                    <v-row>
                      <v-col cols="12" md="6">
                        <h4 class="black--text">Nombre</h4>
                        <v-text-field v-model="service.name" label="Nombre del servicio" outlined solo flat dense
                          hide-details class="mt-2" :rules="[serviceRules.name]"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <h4 class="black--text">Duración</h4>
                        <div class="d-flex flex-row" style="max-width: 300px;">
                          <v-text-field v-model="serviceDurationHours" suffix="horas" outlined dense hide-details
                            class="mt-2" :rules="[serviceRules.duration(service.duration)]"></v-text-field>
                          <v-text-field v-model="serviceDurationMinutes" suffix="minutos" outlined dense hide-details
                            class="mt-2 ml-2" :rules="[serviceRules.duration(service.duration)]"></v-text-field>
                        </div>
                      </v-col>
                      <v-col cols="12" md="2">
                        <h4 class="black--text">Precio</h4>
                        <v-text-field v-model="service.price" :suffix="business.currencyCode" outlined dense
                          hide-details class="mt-2" :rules="[serviceRules.price]"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn block class="primary" @click="handleClickAddService" :disabled="!this.validService">Agregar
                    servicio</v-btn>
                </v-card-actions>
                <v-card-text class="px-0 px-md-4">
                  <v-divider></v-divider>
                  <v-card-title class="black--text">Servicios</v-card-title>
                  <v-card-text v-if="services.length === 0">
                    No se han añadido servicios. Haz clic en el botón para añadir un nuevo servicio.
                  </v-card-text>
                  <v-card v-for="(service, idx) in services" :key="`service-${service.id}`" outlined class="rounded-0"
                    :class="idx == 0 ? 'rounded-t-lg' : idx == services.length - 1 ? 'rounded-b-lg' : ''"
                    :style="idx > 0 ? 'border-top: 0;' : ''" style="z-index: 0;">
                    <v-card-text class="px-lg-5 black--text d-flex flex-row align-center">
                      <v-avatar :size="$vuetify.breakpoint.mdAndDown ? 45 : 60" color="primary" tile
                        class="rounded-xl mr-3 mr-lg-5 white--text" style="opacity: 0.7">
                        {{ service.initials }}
                      </v-avatar>
                      <v-row>
                        <v-col cols="10" class="d-flex flex-column justify-center">
                          <span class="body-2 font-weight-bold">{{ service.name }}</span>
                          <div class="mt-1">
                            <v-chip label :small="!$vuetify.breakpoint.mdAndDown"
                              :x-small="$vuetify.breakpoint.mdAndDown"
                              :class="$vuetify.breakpoint.mdAndDown && 'py-3 ml-0 mr-1'" outlined
                              color="grey lighten-2">
                              <v-icon small left color="grey">mdi-clock</v-icon>
                              <span class="grey--text text--darken-1">
                                {{ getServiceDuration(service.id).hours }}h
                                {{ getServiceDuration(service.id).minutes }}m
                              </span>
                            </v-chip>
                            <v-chip label :small="!$vuetify.breakpoint.mdAndDown"
                              :x-small="$vuetify.breakpoint.mdAndDown"
                              :class="$vuetify.breakpoint.mdAndDown ? 'py-3 ml-0 mr-1' : 'ml-3'" outlined
                              color="grey lighten-2">
                              <v-icon dense left color="grey">mdi-cash</v-icon>
                              <span class="grey--text text--darken-1">{{ formatPrice(service.price,
                                business.currencyCode)
                              }}</span>
                            </v-chip>
                          </div>
                        </v-col>
                        <v-col cols="2" class="d-flex justify-end align-center">
                          <v-btn color="red" class="font-weight-black" icon
                            @click="handleClickDeleteService(service.id)" :x-small="$vuetify.breakpoint.mdAndDown">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-card-text>
                <v-card-actions class="pt-4">
                  <v-spacer></v-spacer>
                  <v-btn text @click="prevStep" class="px-6">Atrás</v-btn>
                  <v-btn color="primary" :disabled="services.length === 0" @click="nextStep" class="px-6">
                    Continuar
                  </v-btn>
                </v-card-actions>
              </v-stepper-content>

              <!-- Paso 3: Tus empleados -->
              <v-stepper-content step="3">
                <v-card-text class="px-0 px-md-4">
                  <v-form ref="employeesForm" lazy-validation>
                    <v-row>
                      <v-col cols="12" md="6">
                        <h4 class="black--text">Nombre del empleado</h4>
                        <v-text-field v-model="employee.fullName" label="Nombre completo" outlined solo flat dense
                          class="mt-2" required :rules="[employeeRules.name]"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <h4 class="black--text">Servicios asociados</h4>
                        <v-autocomplete v-model="employee.services" :items="services" item-text="name" item-value="id"
                          label="Servicios" solo flat outlined dense class="mt-2" multiple return-object
                          :rules="[employeeRules.services]">
                          <template v-slot:selection="{ item }">
                            <v-chip :color="`${item.color?.split(' ')[0]} lighten-3`" small label
                              class="rounded-lg mr-1">
                              {{ item.name }}
                            </v-chip>
                          </template>
                          <template v-slot:prepend-item>
                            <v-list-item @click="allServices = !allServices">
                              <v-list-item-action>
                                <v-checkbox v-model="allServices" readonly color="primary"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>Todos los servicios</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn block class="primary" @click="handleClickAddEmployee" :disabled="!this.validEmployee">Agregar
                    empleado</v-btn>
                </v-card-actions>
                <v-card-text class="px-0 px-md-4">
                  <v-divider></v-divider>
                  <v-card-title class="black--text">Empleados</v-card-title>
                  <v-card-text v-if="employees.length === 0">
                    No se han añadido empleados. Haz clic en el botón para añadir un nuevo empleado.
                  </v-card-text>
                  <v-card v-for="(employee, idx) in employees" :key="`employee-${employee.id}`" outlined
                    class="rounded-0"
                    :class="idx == 0 ? 'rounded-t-lg' : idx == employees.length - 1 ? 'rounded-b-lg' : ''"
                    :style="idx > 0 ? 'border-top: 0;' : ''">
                    <v-card-text class="px-5 black--text d-flex flex-row align-center">
                      <v-avatar :size="$vuetify.breakpoint.mdAndDown ? 45 : 60" :color="employee.color" tile
                        class="rounded-xl mr-5 white--text" style="opacity: 0.7">
                        {{ employee.initials }}
                      </v-avatar>
                      <v-row>
                        <v-col cols="10" class="d-flex flex-column justify-center">
                          <span class="body-1 font-weight-bold grey--text text--darken-3">
                            {{ employee.fullName }}
                            <span class="ml-1 body-2 grey--text text--darken-1">({{
                              getNumberOfServicesFromEmployee(employee) }})</span>
                          </span>
                          <div class="mt-1">
                            <v-chip label :small="!$vuetify.breakpoint.mdAndDown"
                              :x-small="$vuetify.breakpoint.mdAndDown"
                              :class="$vuetify.breakpoint.mdAndDown && 'ml-1 py-3'" outlined
                              :color="`${service.color?.split(' ')[0]} lighten-2`" class="mr-1 mt-1"
                              v-for="service in employee.services" :key="`service-${employee.id}-${service.id}`">
                              <v-icon small left :color="service.color">mdi-tag</v-icon>
                              <span :class="`${service.color?.split(' ')[0]}--text`">
                                {{ service.name }}
                              </span>
                            </v-chip>
                          </div>
                        </v-col>
                        <v-col cols="2" class="d-flex justify-end align-center">
                          <v-btn color="red" class="font-weight-black" icon
                            @click="handleClickDeleteEmployee(employee.id)" :x-small="$vuetify.breakpoint.mdAndDown">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="prevStep" class="px-6">Atrás</v-btn>
                  <v-btn color="primary" class="px-6" :disabled="employees.length === 0" @click="finishWizard">
                    Finalizar
                  </v-btn>
                </v-card-actions>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// Importa las acciones o mapea tus stores según lo necesites
import { mapGetters } from 'vuex'
import PhoneInput from '@/components/common/PhoneInput.vue'
import CurrencyInput from '@/components/common/CurrencyInput.vue'
import AddressDialog from '@/components/business/AddressDialog.vue'
import ScheduleDialog from '@/components/business/ScheduleDialog.vue'
import { getWeekDay, formatPrice } from '@/utils';

export default {
  name: "SetupWizardView",
  components: {
    PhoneInput,
    CurrencyInput,
    AddressDialog,
    ScheduleDialog,
  },
  data() {
    return {
      dialog: true, // Controla la visibilidad del wizard
      addressDialog: false,
      scheduleDialog: false,
      step: 1, // Paso actual del wizard
      // validBusiness: false,
      service: {
        name: "",
        duration: 0,
        price: 0
      },
      employee: {
        fullName: "",
        services: []
      },
      servicesList: [],
      serviceRules: {
        name: value => !!value || 'El nombre del servicio es obligatorio',
        duration: value => value > 0 || 'El servicio debe tener alguna duración',
        price: value => value >= 0 || 'El precio no puede ser negativo o nulo'
      },
      employeeRules: {
        name: value => !!value || 'El nombre del empleado es obligatorio',
        services: value => value.length > 0 || 'Debes asignar al menos un servicio al empleado'
      }
    }
  },
  computed: {
    ...mapGetters(["business", "services", "employees", "getServiceDuration"]),
    addressObject: {
      get() {
        return {
          address: this.business.address,
          locality: this.business.locality,
          city: this.business.city,
          country: this.business.country,
          countryCode: this.business.countryCode,
          postalCode: this.business.postalCode,
          latitude: this.business.latitude,
          longitude: this.business.longitude,
        }
      },
      set(val) {
        this.business.address = val.address
        this.business.locality = val.locality
        this.business.city = val.city
        this.business.country = val.country
        this.business.countryCode = val.countryCode
        this.business.postalCode = val.postalCode
        this.business.latitude = val.latitude
        this.business.longitude = val.longitude
      }
    },
    serviceDurationHours: {
      get() {
        return Math.floor(this.service.duration / 3600)
      },
      set(value) {
        const duration = this.serviceDurationMinutes * 60 + value * 3600
        this.service.duration = duration >= 0 ? duration : 0
      }
    },
    serviceDurationMinutes: {
      get() {
        return Math.floor((this.service.duration % 3600) / 60)
      },
      set(value) {
        const duration = this.serviceDurationHours * 3600 + value * 60
        this.service.duration = duration >= 0 ? duration : 0
      }
    },
    allServices: {
      get() {
        return this.employee.services.length === this.services.length
      },
      set(value) {
        if (value) {
          this.employee.services = Object.assign([], this.services)
        } else {
          this.employee.services = []
        }
      }
    },
    validBusiness() {
      return this.business.name && this.business.phoneNumber && this.business.currencyCode && this.business.address && this.business.schedule.length > 0
    },
    validService() {
      return this.service.name && this.service.duration > 0
    },
    validEmployee() {
      return this.employee.fullName && this.employee.services.length > 0
    }
  },
  created() {
    // Simulación de carga de servicios; en un caso real podrías despachar una acción
    this.loadServices()
    this.loadEmployees()
  },
  methods: {
    getWeekDay,
    formatPrice,
    nextStep() {
      if (this.step < 3) {
        this.step++
      }
    },
    prevStep() {
      if (this.step > 1) {
        this.step--
      }
    },
    handleSaveAddress(address) {
      this.addressObject = address
    },
    handleClickSaveBusinessDetailsAndContinue() {
      // Validar el formulario
      if (!this.$refs.businessForm.validate()) return
      // Guardar los detalles del negocio
      this.$store.dispatch("updateBusiness", this.business)
      this.nextStep()
    },
    async handleClickAddService() {
      // Validar el formulario
      if (!this.validService) return
      // Crear el servicio
      await this.$store.dispatch("createService", this.service)
      await this.$store.dispatch("fetchServices")
      this.service = {
        name: "",
        duration: 0,
        price: 0
      }
      this.$refs.servicesForm.resetValidation()
    },
    async handleClickDeleteService(serviceId) {
      await this.$store.dispatch("deleteService", serviceId)
      await this.$store.dispatch("fetchServices")
    },
    getNumberOfServicesFromEmployee(employee) {
      if (employee.services.length === this.services.length && employee.services.length > 0) return 'Todos los servicios';
      else if (employee.services.length === 0) return 'Ningún servicio';
      else return `${employee.services.length} servicios`;
    },
    async handleClickAddEmployee() {
      // Validar el formulario
      if (!this.validEmployee) return
      // Crear el empleado
      await this.$store.dispatch("createEmployee", this.employee)
      await this.$store.dispatch("fetchEmployees")
      this.employee = {
        fullName: "",
        services: []
      }
      this.$refs.employeesForm.resetValidation()
    },
    async handleClickDeleteEmployee(employeeId) {
      await this.$store.dispatch("deleteEmployee", employeeId)
      await this.$store.dispatch("fetchEmployees")
    },
    async cancelWizard() {
      this.$router.push(`/businesses/${this.business.id}/profile`)
    },
    async finishWizard() {
      this.$router.push(`/businesses/${this.business.id}/profile`)
    },
    async loadServices() {
      await this.$store.dispatch("fetchServices")
    },
    async loadEmployees() {
      await this.$store.dispatch("fetchEmployees")
    }
  }
}
</script>

<style scoped>
/* Puedes agregar estilos personalizados si lo necesitas */
/* .v-stepper__header {
  background-color: #f5f5f5;
} */
.sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
}

.stepper {
  overflow: visible;
}
</style>
