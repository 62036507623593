<template>
    <div>
        <NavigationDrawer :value="!$vuetify.breakpoint.mdAndDown" :items="marketingNavigationItems">
        </NavigationDrawer>
        <v-main app>
            <router-view />
        </v-main>
    </div>
</template>

<script>
import NavigationDrawer from '@/components/business/NavigationDrawer.vue'
import { navigationItems } from '@/utils'

export default {
    name: 'MarketingLayout',
    components: {
        NavigationDrawer
    },
    data() {
        return {

        }
    },
    computed: {
        navigationItems,
        marketingNavigationItems() {
            return this.navigationItems?.find(item => item.name === 'profile').items || []
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
