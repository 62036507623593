<template>
    <div>
        <!-- <v-btn dark color="grey" :block="block" @click="logout" class="text-none font-weight-bold px-6 py-5">
            <v-icon left class="mr-4">mdi-logout</v-icon>
            <span>Cerrar sesión</span>
            <v-spacer></v-spacer>
        </v-btn> -->
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-avatar size="37" color="blue-grey lighten-5 rounded-lg" tile v-bind="attrs" v-on="on">{{ initials
                    }}</v-avatar>
            </template>
            <div class="d-flex align-center white py-3 px-4">
                <v-avatar size="40" color="blue-grey lighten-5 rounded-lg" tile>{{ initials }}</v-avatar>
                <div class="d-flex flex-column align-start justify-start white ml-3">
                    <span class="font-weight-bold">{{ user?.firstName }} {{ user?.lastName }}</span>
                    <span class="font-weight-light" style="font-size: 14px;">{{ user?.email }}</span>
                </div>
            </div>
            <v-list class="py-0">
                <v-list-item dense @click="goesToManageSubscriptionUrl" v-if="business?.subscription">
                    <v-list-item-title>
                        <v-icon left small>mdi-credit-card</v-icon>
                        <span>Facturación</span>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item dense @click="logout">
                    <v-list-item-title>
                        <v-icon left small>mdi-logout</v-icon>
                        <span>Cerrar sesión</span>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>
<script>
import { goesToManageSubscriptionUrl } from '@/utils'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'AvatarButton',
    props: {
        block: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapGetters(['business']),
        user: {
            get() {
                return JSON.parse(localStorage.getItem('user'))
            },
            set(value) {
                localStorage.setItem('user', JSON.stringify(value))
            },
        },
        initials() {
            return this.user?.firstName?.charAt(0)?.toUpperCase() + this.user?.lastName?.charAt(0)?.toUpperCase() || 'UD'
        },
    },
    methods: {
        ...mapActions('auth', ['logout']),
        goesToManageSubscriptionUrl,
    },
    created() {
        if (!this.business) {
            this.$store.dispatch('business/fetchBusiness')
        }
    },
}
</script>