<template>
    <v-autocomplete v-model="selectedCurrencyCode" style="width: 150px !important;" outlined dense class="mt-2"
        :items="currencyCodes" item-value="code" :item-text="item => JSON.stringify(item)" single-line>
        <template v-slot:selection="{ item }">
            <div class="d-flex justify-space-between align-center" style="width: 100%;">
                <span>{{ item.code }}</span>
                <v-chip small color="primary" dark class="ml-4 px-2">{{ item.symbol }}</v-chip>
            </div>
        </template>
        <template v-slot:item="{ item }">
            <div class="d-flex justify-space-between align-center" style="width: 100%;">
                <span>{{ item.code }}</span>
                <v-chip small color="primary" dark class="ml-4 text-center px-2">{{ item.symbol }}</v-chip>
            </div>
        </template>
    </v-autocomplete>
</template>

<script>

export default {
    props: {
        value: {
            type: String,
            required: false,
        },
        rules: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            currencyCodes: [],
        };
    },
    computed: {
        selectedCurrencyCode: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    created() {
        const currencyCodes = require('@/assets/currency-codes.json')
        this.currencyCodes = currencyCodes;
    }
};
</script>