import { FEATURES, SUBSCRIPTION_PLANS } from "@/consts";
import router from "@/plugins/router";
import store from "@/plugins/store";
import api from "@/services/api";
import moment from 'moment'

export const formatDatetime = (date, dateFormat = 'yyyy-MM-dd') => {
    if (typeof date === 'string') date = new Date(date)
    if (!date) date = new Date()
    // Format 'yyyy-MM-dd HH:mm'
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')

    if (dateFormat === 'yyyy-MM-dd HH:mm') {
        return `${year}-${month}-${day} ${hours}:${minutes}`
    } else if (dateFormat === 'yyyy-MM-dd HH:mm:ss') {
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    } else if (dateFormat === 'yyyy-MM-dd') {
        return `${year}-${month}-${day}`
    } else if (dateFormat === 'HH:mm') {
        return `${hours}:${minutes}`
    } else {
        return date.toISOString()
    }
}

export const truncateDecimals = (value, decimals = 2) => parseFloat(value.toFixed(decimals))

export const currentPlan = (hasPaid = false) => {
    if (!store.getters.business?.id) return SUBSCRIPTION_PLANS.PREMIUM
    if (isTrialVersion() && !hasPaid) {
        return SUBSCRIPTION_PLANS.PREMIUM
    }
    return store.getters.business?.subscription?.plan || SUBSCRIPTION_PLANS.BASIC
}

export const isTrialVersion = () => {
    if (!store.getters.business?.id) return false
    if (store.getters.business.trialEndsAt) {
        const trialEndsAt = new Date(store.getters.business.trialEndsAt)
        const now = new Date()
        return trialEndsAt > now
    }
    return false
}

export const hasStripeAccount = () => {
    return !store.getters.business?.subscription
}

export const daysLeftForTrial = () => {
    if (!store.getters.business?.id) return 0
    if (store.getters.business.trialEndsAt) {
        const trialEndsAt = new Date(store.getters.business.trialEndsAt)
        const now = new Date()
        return Math.ceil((trialEndsAt - now) / (1000 * 60 * 60 * 24))
    }
    return 0
}

export const goesToChangePlan = async () => {
    let url
    if (currentPlan(true) === SUBSCRIPTION_PLANS.BASIC) {
        router.push(`/businesses/${store.getters.business?.id}/prices`)
        return
    } else {
        const response = await api.get(`/subscriptions/manage-subscription`)
        url = `${response.data.url}/subscriptions/${store.getters.business?.subscription?.stripeSubscriptionId}/update`
    }
    window.location = url
}

export const goesToManageSubscriptionUrl = async () => {
    const response = await api.get(`/subscriptions/manage-subscription`)
    const url = response.data.url
    window.location = url
}

export function isFeatureAvailable(featureKey) {
    const features = store.getters.features;
    return featureKey in features && features[featureKey] !== false;
}

export function getFeatureValue(featureKey) {
    const features = store.getters.features;
    return features[featureKey];
}

export function isFeatureLimited(featureKey) {
    return getFeatureValue(featureKey) > 0;
}

export function canAddAppointment() {
    const features = store.getters.features;
    const maxAppointments = features.MonthlyAppointments;

    // Si el plan es ilimitado (maxAppointments = 0), siempre permite más reservas
    if (maxAppointments === 0) {
        return true;
    }

    const currentMonthCount = filterAppointmentsOfCurrentMonth(store.getters.appointments).length;

    // Verifica si el número actual de reservas supera el límite
    return currentMonthCount < maxAppointments;
}

export const filterAppointmentsOfCurrentMonth = (appointments) => {
    const today = new Date()
    const currentMonth = today.getMonth()
    const currentYear = today.getFullYear()
    return appointments.filter(appointment => {
        const appointmentDate = new Date(appointment.start)
        return appointmentDate.getMonth() === currentMonth && appointmentDate.getFullYear() === currentYear
    })
}

export function canAddServices() {
    const features = store.getters.features;
    const maxServices = features.Services;
    console.log('maxServices', maxServices)
    // Si el plan es ilimitado (maxServices = 0), siempre permite más servicios
    if (maxServices === 0) {
        return true;
    }

    // Verifica si el número actual de servicios supera el límite
    return store.getters.services.length < maxServices;
}

export function canAddEmployees() {
    const features = store.getters.features;
    const maxEmployees = features.Employees;
    console.log('maxEmployees', maxEmployees)
    // Si el plan es ilimitado (maxEmployees = 0), siempre permite más empleados
    if (maxEmployees === 0) {
        return true;
    }

    // Verifica si el número actual de empleados supera el límite
    return store.getters.employees.length < maxEmployees;
}

export function canAddClients() {
    const features = store.getters.features;
    const maxClients = features.TotalClients;
    console.log('maxClients', maxClients)
    // Si el plan es ilimitado (maxClients = 0), siempre permite más clientes
    if (maxClients === 0) {
        return true;
    }

    // Verifica si el número actual de clientes supera el límite
    return store.getters.clients.length < maxClients;
}

export function getWeekDay(day) {
    return moment().locale('es').day(day).format('dddd')
}

export function formatPrice(price, currency = 'EUR') {
    return Intl.NumberFormat('es-ES', { style: 'currency', currency }).format(price);
}

export function navigationItems() {
    const businessId = localStorage.getItem('businessId')
    return [
        {
            icon: 'mdi-calendar',
            title: 'Calendario',
            name: 'calendar',
            path: `/businesses/${businessId}/calendar`,
            items: [],
        },
        {
            icon: 'mdi-account-group',
            title: 'Clientes',
            name: 'clients',
            path: `/businesses/${businessId}/clients`,
            items: [],
        },
        {
            icon: 'mdi-web',
            title: 'Página web',
            name: 'profile',
            path: `/businesses/${businessId}/profile`,
            items: [
                {
                    title: 'Enlaces para clientes',
                    path: `/businesses/${businessId}/profile/links`,
                    icon: 'mdi-link-variant'
                },
                {
                    title: 'Perfil de la empresa',
                    path: `/businesses/${businessId}/profile/details`,
                    icon: 'mdi-store-cog'
                },
                {
                    title: 'Servicios',
                    path: `/businesses/${businessId}/profile/services`,
                    icon: 'mdi-cash-register'
                },
                {
                    title: 'Empleados',
                    path: `/businesses/${businessId}/profile/employees`,
                    icon: 'mdi-card-account-details'
                },
            ],
        },
        {
            icon: 'mdi-cog',
            title: 'Ajustes',
            name: 'settings',
            path: `/businesses/${businessId}/settings`,
            items: [
                {
                    title: 'Ajustes de reservas',
                    path: `/businesses/${businessId}/settings/rules`,
                    icon: 'mdi-timer-cog'
                },
                {
                    title: 'Ausencia laboral',
                    path: `/businesses/${businessId}/settings/vacations`,
                    locked: !isFeatureAvailable(FEATURES.Vacations),
                    icon: 'mdi-beach'
                },
                {
                    title: 'Notificaciones',
                    name: 'notifications',
                    path: `/businesses/${businessId}/settings/notifications`,
                    icon: 'mdi-bell-cog'
                },
            ],
        },
        {
            icon: 'mdi-store',
            title: 'Actividad',
            name: 'activity',
            path: `/businesses/${businessId}/activity`,
            items: [],
            locked: !isFeatureAvailable(FEATURES.BusinessAnalytics)
        },
    ]
}
