<template>
    <div>
        <v-navigation-drawer app clipped>
            <v-card flat class="pa-5">
                <BlockingBadge :locked="!canAddAppointment()">
                    <v-tooltip bottom
                        :value="createBookingTooltip && !hasServicesAndEmployees && $vuetify.breakpoint.mdAndUp">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="!hasServicesAndEmployees && on">
                                <v-btn class="py-5 mt-2 body-2 font-weight-bold elevation-0 rounded-lg"
                                    color="teal accent-4" block :dark="bookingEnabled" @click="createBooking"
                                    :disabled="!bookingEnabled">
                                    <v-icon left>mdi-plus</v-icon>
                                    Crear
                                    reserva
                                </v-btn>
                            </div>
                        </template>
                        <span>Para crear una reserva tienes que crear al menos un Empleado y un
                            Servicio</span>
                    </v-tooltip>
                </BlockingBadge>
                <ButtonSubscriptionLimit variant="Appointments" />
                <CardPurchaseCredits variant="calendar" class="mt-2" />
                <MiniCalendarComponent v-model="calendarFocus" :type="calendarType" />
                <v-card flat class="my-5">
                    <v-card-title class="px-1 py-0">
                        <span class="text-uppercase body-2 font-weight-bold">Equipo</span>
                    </v-card-title>
                    <v-card-text class="pa-0">
                        <v-list dense nav>
                            <v-list-item-group v-model="selectedPeopleFilter" color="teal accent-4" mandatory>
                                <template>
                                    <v-list-item :value="0">
                                        <template>
                                            <v-list-item-icon class="mx-2">
                                                <v-icon left small>mdi-account-group</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title
                                                    class="font-weight-bold caption grey--text text--darken-2">Todo el
                                                    equipo</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                    <v-list-item v-for="employee in employees" :key="employee.id" :value="employee.id">
                                        <template>
                                            <v-list-item-icon class="mx-2">
                                                <v-icon left small>mdi-account</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title
                                                    class="font-weight-bold caption grey--text text--darken-2">{{
                                                        employee.fullName }}</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                    <v-list-item :value="1">
                                        <template>
                                            <v-list-item-icon class="mx-2">
                                                <v-icon left small>mdi-account-lock-open</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title
                                                    class="font-weight-bold caption grey--text text--darken-2">No
                                                    asignado</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-card>
        </v-navigation-drawer>
        <v-main app style="height: calc(100vh - 68px); position:relative;">
            <v-toolbar flat v-if="$vuetify.breakpoint.mdAndDown" class="pr-md-2">
                <v-btn small class="py-5 mr-2 ml-n1 body-2 font-weight-bold elevation-0 rounded-lg d-lg-none"
                    color="teal accent-4" dark @click="reloadAppointments" outlined>
                    <v-icon>mdi-sync</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title v-if="calendarType == 'week'" class="text-h6 grey--text text--darken-2">Del {{
                    getMainDaysOfWeek().firstDayOfWeek }} al {{ getMainDaysOfWeek().lastDayOfWeek }} de <span
                        class="text-capitalize">{{ currentMonth }}</span> </v-toolbar-title>
                <v-toolbar-title v-if="calendarType == 'category'"
                    class="text-h6 grey--text text--darken-2 text-capitalize">{{
                        currentDayOfWeek }}, {{ currentDay }} <span class="text-lowercase">de</span> {{ currentMonth }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <ButtonSubscriptionLimit variant="Appointments" mini />
            </v-toolbar>
            <v-toolbar flat>
                <v-btn small class="py-5 mr-3 ml-n1 body-2 font-weight-bold elevation-0 rounded-lg d-lg-flex d-none"
                    color="teal accent-4" dark @click="reloadAppointments" outlined>
                    <v-icon>mdi-sync</v-icon>
                </v-btn>
                <v-toolbar-title v-if="calendarType == 'week'"
                    class="text-h6 grey--text text--darken-2 d-none d-lg-block">Del
                    {{
                        getMainDaysOfWeek().firstDayOfWeek }} al {{ getMainDaysOfWeek().lastDayOfWeek }} de <span
                        class="text-capitalize">{{ currentMonth }}</span> </v-toolbar-title>
                <v-toolbar-title v-if="calendarType == 'category'"
                    class="text-h6 grey--text text--darken-2 text-capitalize d-none d-lg-block">{{ currentDayOfWeek }},
                    {{
                        currentDay }} <span class="text-lowercase">de</span> {{ currentMonth }} </v-toolbar-title>
                <v-spacer class="d-none d-lg-block"></v-spacer>
                <BlockingBadge :locked="!canAddAppointment()" class="d-lg-none" variant="small-button">
                    <!-- <v-btn small class="py-5 mr-2 ml-n1 body-2 font-weight-bold elevation-0 rounded-lg"
                        color="teal accent-4" :dark="bookingEnabled" @click="createBooking" :disabled="!bookingEnabled">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn> -->
                    <v-tooltip bottom
                        :value="createBookingTooltip && !hasServicesAndEmployees && !$vuetify.breakpoint.mdAndUp">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="!hasServicesAndEmployees && on">
                                <v-btn small class="py-5 mr-2 ml-n1 body-2 font-weight-bold elevation-0 rounded-lg"
                                    color="teal accent-4" :dark="bookingEnabled" @click="createBooking"
                                    :disabled="!bookingEnabled">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <span>Para crear una reserva tienes que crear al menos un Empleado y un
                            Servicio</span>
                    </v-tooltip>
                </BlockingBadge>
                <v-btn class="pa-5 elevation-0 rounded-lg rounded-r-0 caption font-weight-bold" small
                    :color="calendarType == 'category' ? 'teal accent-4' : 'grey lighten-3'"
                    :dark="calendarType == 'category'" @click="calendarType = 'category'">
                    Día
                </v-btn>
                <v-btn class="pa-5 elevation-0 rounded-lg rounded-l-0 caption font-weight-bold" small
                    :color="calendarType == 'week' ? 'teal accent-4' : 'grey lighten-3'" :dark="calendarType == 'week'"
                    @click="calendarType = 'week'">
                    Semana
                </v-btn>
                <v-spacer class="mr-1"></v-spacer>
                <v-btn class="py-5 elevation-0 rounded-lg rounded-r-0 caption font-weight-bold" small
                    color="grey lighten-3" @click="prevDay">
                    <v-icon dense>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn class="py-5 elevation-0 rounded-0 caption font-weight-bold" small
                    :color="calendarFocus == '' ? 'teal accent-4' : 'grey lighten-3'" :dark="calendarFocus == ''"
                    @click="setToday">
                    Hoy
                </v-btn>
                <v-btn class="py-5 elevation-0 rounded-lg rounded-l-0 caption font-weight-bold" small
                    color="grey lighten-3" @click="nextDay">
                    <v-icon dense>mdi-chevron-right</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container fluid class="pa-0" style="height:100%; overflow:auto;">
                <v-sheet elevation="0" style="height:100%; overflow:auto;">
                    <!-- <v-calendar 
                        style="border-left: none !important; border-right: none !important;"
                        ref="calendar"
                        v-model="calendarFocus" 
                        color="primary" 
                        locale="es"
                        :type="calendarType" 
                        category-show-all 
                        :categories="categories"
                        :events="events" 
                        :event-color="getEventColor" 
                        locale-first-day-of-year="1"
                        @change="fetchEvents">
                    </v-calendar> -->
                    <v-calendar
                        style="border-left: none !important; border-right: none !important; height:100%; overflow:auto;"
                        ref="calendar" v-model="calendarFocus" color="primary" locale="es" :type="calendarType"
                        category-show-all event-color="white" :categories="categories" :weekdays="weekdaysOrder"
                        :interval-format="interval => interval.hour + ':' + (interval.minute < 10 ? '0' : '') + interval.minute"
                        :events="filteredEvents" :event-timed="() => true">
                        <template v-slot:day-body="{ date, week }">
                            <div class="v-current-time" :class="{ first: date === week[0].date }"
                                :style="{ top: nowY }"></div>
                        </template>
                        <!-- <template v-slot:event="{ event, timed, eventSummary }"> -->
                        <template v-slot:event="{ event }">
                            <div v-ripple class="v-event-draggable my-event" :class="{
                                'not-accepted-event teal--text text--darken-3': event.appointment.bookings[0].status == BOOKING_STATUSES.PENDING,
                                'accepted-event teal--text text--darken-3': event.appointment.bookings[0].status == BOOKING_STATUSES.CONFIRMED,
                                'cancelled-event red--text text--darken-3': event.appointment.bookings[0].status == BOOKING_STATUSES.CANCELLED,
                                'client-absent-event orange--text text--darken-3': event.appointment.bookings[0].status == BOOKING_STATUSES.CLIENT_ABSENT,
                                'client-arrived-event teal--text text--darken-3': event.appointment.bookings[0].status == BOOKING_STATUSES.CLIENT_ARRIVED,
                            }" style="height: 100%; border-radius: 5px; width: 100%;"
                                @click="editAppointment(event.appointment.id)">
                                <!-- <v-icon color="white" small left ></v-icon> -->
                                <!-- <span class="ml-n1" v-html="eventSummary()"></span>    -->
                                <span class="ml-2 " :class="!$vuetify.breakpoint.mdAndDown && 'text-truncate'">{{
                                    event.title }}</span>
                                <!-- <v-chip
                                x-small
                                dark
                                color="warning"
                                class="ml-2"
                                label
                                >
                                    <v-icon small left>mdi-bell-outline</v-icon>2
                                </v-chip> -->
                                <p v-if="event.duration >= 45" class="ml-2 font-weight-regular"
                                    :class="!$vuetify.breakpoint.mdAndDown && 'text-truncate'">{{ event.category }}</p>
                            </div>
                            <!-- <div
                            v-if="timed"
                            class="v-event-drag-bottom"
                            ></div> -->
                        </template>
                    </v-calendar>
                </v-sheet>
            </v-container>
            <AppointmentFormDialogComponent v-model="appointmentFormDialog" :appointmentId="formAppointmentId" />
        </v-main>
        <DialogSubscriptionLimit v-model="showSubscriptionLimitDialog"
            :variant="SUBSCRIPTION_LIMIT_VARIANTS.Appointments"
            :maxLimit="getFeatureValue(FEATURES.MonthlyAppointments)" :currentCount="currentAppointmentsCount" />
    </div>
</template>

<script>
import MiniCalendarComponent from '@/components/business/MiniCalendarComponent'
import AppointmentFormDialogComponent from '@/components/business/AppointmentFormDialogComponent'
import DialogSubscriptionLimit from '@/components/business/DialogSubscriptionLimit'
import BlockingBadge from '@/components/business/BlockingBadge'
import ButtonSubscriptionLimit from '@/components/business/ButtonSubscriptionLimit'
import CardPurchaseCredits from '@/components/business/CardPurchaseCredits'

import { mapGetters, mapActions, mapMutations } from 'vuex'
import { BOOKING_STATUSES, SUBSCRIPTION_LIMIT_VARIANTS, FEATURES } from '@/consts'
import { canAddAppointment, getFeatureValue, isFeatureLimited } from '@/utils'

let syncInterval = null

export default {
    name: 'CalendarView',
    components: {
        MiniCalendarComponent,
        AppointmentFormDialogComponent,
        DialogSubscriptionLimit,
        BlockingBadge,
        ButtonSubscriptionLimit,
        CardPurchaseCredits,
    },
    data() {
        return {
            FEATURES,
            BOOKING_STATUSES,
            SUBSCRIPTION_LIMIT_VARIANTS,
            selectedPeopleFilter: 0,
            calendarType: 'category',
            calendarFocus: '',
            // categories: [],
            // Demo
            // events: [],
            ready: false,
            weekdaysOrder: [1, 2, 3, 4, 5, 6, 0],
            // Forms
            appointmentFormDialog: false,
            formAppointmentId: 'new',
            showSubscriptionLimitDialog: isFeatureLimited(FEATURES.MonthlyAppointments),
            createBookingTooltip: false,
        }
    },
    computed: {
        ...mapGetters(['business', 'employees', 'services', 'appointments', 'currentAppointmentsCount']),
        usagePercentage() {
            return this.currentAppointmentsCount / getFeatureValue(FEATURES.MonthlyAppointments) * 100
        },
        accentColor() {
            if (this.usagePercentage < 50) {
                return 'primary';
            } else if (this.usagePercentage < 75) {
                return 'warning';
            } else {
                return 'red';
            }
        },
        currentMonth() {
            const date = this.calendarFocus != '' ? new Date(this.calendarFocus) : new Date();
            return new Intl.DateTimeFormat('default', { month: 'long' }).format(date)
        },
        currentDay() {
            const date = this.calendarFocus != '' ? new Date(this.calendarFocus) : new Date();
            return date.getDate()
        },
        currentDayOfWeek() {
            const date = this.calendarFocus != '' ? new Date(this.calendarFocus) : new Date();
            return new Intl.DateTimeFormat('default', { weekday: 'long' }).format(date)
        },
        bookingEnabled() {
            return this.hasServicesAndEmployees && canAddAppointment()
        },
        hasServicesAndEmployees() {
            return this.employees?.length > 0 && this.services?.length > 0
        },
        cal() {
            return this.ready ? this.$refs.calendar : null
        },
        nowY() {
            return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
        },
        events() {
            let events = []
            this.appointments.forEach(appointment => {
                const start = new Date(appointment.start)
                const end = new Date(appointment.end)
                const title = appointment.title
                const category = this.employees.find(employee => employee.id === appointment.employeeId)?.fullName
                const duration = (end.getTime() - start.getTime()) / 60 / 1000

                events.push({
                    title,
                    start,
                    end,
                    category,
                    duration,
                    appointment
                })
            })

            return events
        },
        filteredEvents() {
            return this.events.filter(event => this.categories.includes(event.category))
        },
        categories() {
            if (!this.ready) return []
            if (this.selectedPeopleFilter === 0) return this.employees.map(employee => employee.fullName)
            else if (this.selectedPeopleFilter === 1) return []
            else return [this.employees.find(employee => employee.id === this.selectedPeopleFilter).fullName]
        }
    },
    watch: {
        events() {
            this.$nextTick(() => {
                this.fillOutBusinessSchedule()
            })
        },
        calendarType() {
            this.$nextTick(() => {
                this.fillOutBusinessSchedule()
            })
        },
        business() {
            this.$nextTick(() => {
                this.fillOutBusinessSchedule()
            })
        },
        calendarFocus() {
            this.$nextTick(() => {
                this.fillOutBusinessSchedule()
            })
        }
    },
    async created() {
        if (this.employees.length == 0) await this.fetchEmployees()
        if (!this.business.schedule || this.business.schedule.length == 0) await this.fetchBusiness()
        if (this.appointments.length == 0) await this.fetchAppointments()
        this.selectedPeopleFilter = 0
        this.$nextTick(() => {
            this.fillOutBusinessSchedule()
            setTimeout(() => {
                this.createBookingTooltip = true
            }, 500)
        })
    },
    mounted() {
        // Demo 
        this.ready = true
        this.scrollToTime()
        this.updateTime()
        this.autoSync()
    },
    destroyed() {
        clearInterval(syncInterval)
    },
    methods: {
        ...mapActions(['fetchBusiness', 'fetchAppointments', 'fetchEmployees', 'fetchServices']),
        ...mapMutations(['setSnackbar', 'startLoading', 'stopLoading']),
        canAddAppointment,
        getFeatureValue,
        isFeatureLimited,
        setToday() {
            this.calendarFocus = ''
        },
        prevDay() {
            this.$refs.calendar.prev()
        },
        nextDay() {
            this.$refs.calendar.next()
        },
        getMainDaysOfWeek() {
            let now = this.calendarFocus != '' ? new Date(this.calendarFocus) : new Date();
            let firstDayOfWeek = (new Date(now.setDate(now.getDate() - (now.getDay() === 0 ? 6 : now.getDay() - 1)))).getDate();
            let lastDayOfWeek = (new Date(now.setDate(now.getDate() - (now.getDay() === 0 ? 6 : now.getDay() - 1) + 6))).getDate();
            return { firstDayOfWeek, lastDayOfWeek };
        },
        rnd(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },
        getCurrentTime() {
            return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
        },
        scrollToTime() {
            const time = this.getCurrentTime()
            const first = Math.max(0, time - (time % 30) - 30)

            this.cal.scrollToTime(first)
        },
        updateTime() {
            setInterval(() => this.cal.updateTimes(), 60 * 1000)
        },
        fillOutBusinessSchedule() {
            if (!this.business.schedule || this.business.schedule.length == 0) return

            const fillIntervals = (intervals, scheduleDay) => {
                intervals.forEach((interval, hour) => {
                    if (scheduleDay.isOpen) {
                        // opening: [{
                        //     open: "09:00",
                        //     close: "14:00"
                        // }, {
                        //     open: "16:00",
                        //     close: "20:00"
                        // }]
                        scheduleDay.opening.forEach(opening => {
                            const openHour = parseInt(opening.open.split(":")[0])
                            const openMinute = parseInt(opening.open.split(":")[1])
                            const closeHour = parseInt(opening.close.split(":")[0])
                            const closeMinute = parseInt(opening.close.split(":")[1])
                            if (hour === openHour) {
                                interval.classList.add("open-time")
                                if (openMinute > 0) {
                                    interval.classList.add("open-time-half")
                                }
                            } else if (hour === closeHour) {
                                interval.classList.add("close-time")
                                if (closeMinute > 0) {
                                    interval.classList.add("close-time-half")
                                }
                            } else if (hour > openHour && hour < closeHour) {
                                interval.classList.add("open-time")
                            } else {
                                interval.classList.add("close-time")
                            }
                        })

                    } else {
                        interval.classList.add("close-time")
                    }
                })
            }

            if (this.calendarType === 'category') {
                const currentDay = this.calendarFocus !== '' ? new Date(this.calendarFocus) : new Date()
                const currentDayOfWeek = currentDay.getDay()
                // TODO: change forEach to find
                const scheduleDay = this.business.schedule.find(scheduleDay => scheduleDay.day === currentDayOfWeek)
                if (scheduleDay?.day === currentDayOfWeek) {
                    const columns = document.querySelectorAll(".v-calendar-daily__day")
                    columns.forEach(column => {
                        const intervals = column.querySelectorAll(".v-calendar-daily__day-interval")
                        fillIntervals(intervals, scheduleDay)
                    })
                }
            }
            else if (this.calendarType === 'week') {
                const columns = document.querySelectorAll(".v-calendar-daily__day")
                this.business.schedule.forEach(scheduleDay => {
                    const column = columns[this.weekdaysOrder.indexOf(scheduleDay.day)]
                    const intervals = column.querySelectorAll(".v-calendar-daily__day-interval")
                    fillIntervals(intervals, scheduleDay)
                })
            }
        },
        autoSync() {
            clearInterval(syncInterval)
            syncInterval = setInterval(() => {
                this.fetchAppointments()
            }, 60000)
        },
        async reloadAppointments() {
            this.startLoading()
            await this.fetchAppointments()
            this.stopLoading()
            this.setSnackbar({ show: true, text: 'Agenda actualizada', color: 'success' })
            this.autoSync()
        },
        // Forms
        createBooking() {
            this.appointmentFormDialog = true
            this.formAppointmentId = 'new'
        },
        editAppointment(appointmentId) {
            this.appointmentFormDialog = true
            this.formAppointmentId = appointmentId
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/* Apunta a cada div de hora */
.v-calendar-daily__day-interval {
    position: relative;
}

/* Agrega una línea discontinua a la mitad de cada div de hora */
.v-calendar-daily__day-interval::after {
    content: '';
    position: absolute;
    top: 50%;
    /* Posiciona la línea en la mitad de la hora */
    left: 0;
    right: 0;
    border-top: 1px dashed #ccc;
    /* Agrega la línea discontinua */
}

/* Obtener el elemento 11 .v-calendar-daily__day-interval y agregar fondo marrón*/
.close-time {
    background-color: rgba(169, 169, 169, 0.315);
}

.open-time {
    background-color: white;
}

.close-time-half::after {
    content: '';
    position: absolute;
    top: 50%;
    /* Posiciona la línea en la mitad de la hora */
    left: 0;
    right: 0;
    height: 50%;
    background: rgba(169, 169, 169, 0.315);
    /* background: red; */
}

.open-time-half::after {
    content: '';
    position: absolute;
    top: 0%;
    /* Posiciona la línea en la mitad de la hora */
    left: 0;
    right: 0;
    height: 50%;
    background: rgba(169, 169, 169, 0.315);
    /* background: red; */
}

.not-accepted-event {
    /* background-color: teal */
    background: repeating-linear-gradient(45deg,
            /* rgba(0, 0, 0, 0) 20px,
      rgba(0, 0, 0, 0) 40px, */
            /* rgba(84, 110, 122, 0.7) 20px,
      rgba(84, 110, 122, 0.7) 40px, */
            rgba(0, 150, 136, 0.4) 10px,
            rgba(0, 150, 136, 0.4) 20px,
            rgba(0, 150, 136, 0.2) 20px,
            rgba(0, 150, 136, 0.2) 30px);
    /* background: repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.6) 10px,
      rgba(255, 255, 255, 0.6) 20px,
      rgba(255, 255, 255, 0.2) 20px,
      rgba(255, 255, 255, 0.2) 30px
    ); */
    border-left: 4px solid #008080 !important;
}

.accepted-event {
    /* teal lighten-4 */
    background-color: rgba(0, 128, 128, 0.2) !important;
    border-left: 4px solid #008080 !important;
}

.cancelled-event {
    /* red lighten-4 */
    background-color: rgba(255, 0, 0, 0.2);
    border-left: 4px solid #FF0000 !important;
}

.client-absent-event {
    /* orange lighten-4 */
    background-color: rgba(255, 165, 0, 0.2);
    border-left: 4px solid #FFA500 !important;
}

.client-arrived-event {
    /* teal lighten-4 */
    background-color: rgba(0, 128, 128, 0.2);
    border-left: 4px solid #008080 !important;
}
</style>

<style lang="scss">
.v-current-time {
    height: 2px;
    background-color: #00BFA5;
    position: absolute;
    left: -1px;
    right: 0;
    pointer-events: none;

    // &.first::before {
    //     content: '';
    //     position: absolute;
    //     background-color: #ea4335;
    //     width: 12px;
    //     height: 12px;
    //     border-radius: 50%;
    //     margin-top: -5px;
    //     margin-left: -6.5px;
    // }
}

.v-event-timed {
    z-index: 1;
}
</style>