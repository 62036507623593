<template>
    <div>
        <v-row class="pt-2">
            <v-col cols="12" class="pa-1">
                <v-card outlined :color="isClientBlocked ? 'red lighten-4' : ''">
                    <v-card-text class="pa-2">
                        <v-row>
                            <v-col cols="12">
                                <v-card class="pa-0 d-flex flex-row transparent" flat>
                                    <v-avatar size="65" :color="client.color" style="opacity: 0.7"
                                        class="ma-2 rounded-lg white--text text-h5" tile>{{ client.initials
                                        }}</v-avatar>
                                    <v-card-text class="pl-0 pt-1 pb-0">
                                        <p class="pa-0 ma-0 mt-1">
                                            <span class="ml-2 font-weight-bold subtitle-2">{{ client.fullName }}</span>
                                        </p>
                                        <p class="pa-0 ma-0 mt-n1">
                                            <span class="ml-2 font-weight-light subtitle-2">{{ client.email }}</span>
                                        </p>
                                        <p class="pa-0 mt-n1">
                                            <span class="ml-2 font-weight-light subtitle-2">{{ client.phoneNumber
                                                }}</span>
                                        </p>
                                    </v-card-text>
                                    <v-card-actions class="d-flex flex-row align-start">
                                        <div class="d-flex align-center">
                                            <span class="font-weight-black red--text mr-4"
                                                v-if="isClientBlocked">Bloqueado</span>
                                            <v-menu offset-y offset-x class="pa-3 rounded-lg">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn x-small class="py-4 rounded-lg text-none" dark v-bind="attrs"
                                                        v-on="on" outlined color="grey darken-2">
                                                        <v-icon small>mdi-dots-vertical</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list class="pr-4">
                                                    <v-list-item link @click="edit">
                                                        <v-list-item-title class="body-2">
                                                            <v-icon left>mdi-pencil</v-icon>
                                                            Editar cliente
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <BlockingBadge :locked="!isClientsBlacklist">
                                                        <v-list-item link @click="block" v-if="!isClientBlocked"
                                                            :disabled="!isClientsBlacklist">
                                                            <v-list-item-title class="body-2"
                                                                :class="isClientsBlacklist ? 'red--text' : ''">
                                                                <v-icon left
                                                                    :color="isClientsBlacklist ? 'red' : 'grey'">mdi-cancel</v-icon>
                                                                Bloquear cliente
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item link @click="unblock" v-else
                                                            :disabled="!isClientsBlacklist">
                                                            <v-list-item-title class="body-2"
                                                                :class="isClientsBlacklist ? 'teal--text' : ''">
                                                                <v-icon left
                                                                    :color="isClientsBlacklist ? 'teal' : 'grey'">mdi-check-circle-outline</v-icon>
                                                                Desbloquear cliente
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                    </BlockingBadge>
                                                    <v-list-item link @click="remove">
                                                        <v-list-item-title class="body-2 red--text">
                                                            <v-icon left color="red">mdi-delete-outline</v-icon>
                                                            Eliminar cliente
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <!-- <v-card-text class="px-4 pt-0 pb-6">
                        <v-row>
                            <v-col cols="5" class="py-0">
                                <span class="subtitle-2 grey--text text--darken-1 text-uppercase">Política de privacidad</span>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <span class="subtitle-2 grey--text text--darken-1 text-uppercase">Sí</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="5" class="py-0">
                                <span class="subtitle-2 grey--text text--darken-1 text-uppercase">Notificaciones de marketing</span>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <span class="subtitle-2 grey--text text--darken-1 text-uppercase">No</span>
                            </v-col>
                        </v-row>
                    </v-card-text> -->
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col cols="3" class="d-flex flex-column text-center px-0">
                                <span class="body-2 black--text font-weight-bold">
                                    {{ client.activitySummary?.totalBookings || 0 }}
                                </span>
                                <span class="caption grey--text text--darken-1 font-weight-bold text-break">Total de
                                    reservas</span>
                            </v-col>
                            <v-col cols="3" class="d-flex flex-column text-center px-0">
                                <span class="body-2 teal--text text--darken-1 font-weight-bold">
                                    {{ client.activitySummary?.pastBookings || 0 }}
                                </span>
                                <span class="caption grey--text text--darken-1 font-weight-bold text-break">Reservas
                                    anteriores</span>
                            </v-col>
                            <v-col cols="3" class="d-flex flex-column text-center px-0">
                                <span class="body-2 black--text font-weight-bold">
                                    {{ client.activitySummary?.upcomingBookings || 0 }}
                                </span>
                                <span class="caption grey--text text--darken-1 font-weight-bold text-break">Próximas
                                    reservas</span>
                            </v-col>
                            <v-col cols="3" class="d-flex flex-column text-center px-0">
                                <span class="body-2 red--text text--darken-1 font-weight-bold">
                                    {{ client.activitySummary?.noShowBookings || 0 }}
                                </span>
                                <span class="caption grey--text text--darken-1 font-weight-bold text-break">No
                                    presentado</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card outlined class="pa-3 mt-3">
                    <v-card-title class="pa-0 font-weight-bold px-3">
                        Notas
                    </v-card-title>
                    <!-- <v-card-text class="px-3 py-1 mb-1 note" v-for="(note, key) in client.notes" :key="note.date" @click="editNote(key)">
                        <span class="body-1 font-weight-bold">{{ moment(note.date).locale('es').fromNow() }}<v-chip v-if="note.updated" class="caption ml-4 mb-1 px-1 grey--text text--darken-1" label x-small>Editado</v-chip></span>
                        <p class="body-2" v-for="(p, pIdx) in note.content.split('\n')" :key="`p${pIdx}`">{{ p }}</p>
                    </v-card-text> -->
                    <v-card-text class="px-0 py-1 mb-1" v-if="isClientsNotes">
                        <v-list-item-group>
                            <v-list-item v-for="(note, noteIndex) in client.notes" :key="note.date"
                                @click="editNote(noteIndex)">
                                <v-list-item-content>
                                    <p class="body-2" v-for="(p, pIdx) in note.content.split('\n')" :key="`p${pIdx}`">{{
                                        p }}</p>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-chip v-if="note.updated" class="caption ml-4 mb-1 px-1 grey--text grey lighten-3"
                                        label x-small>Editada</v-chip>
                                    <v-list-item-action-text>{{ moment(note.date).locale('es').fromNow()
                                        }}</v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list-item-group>
                    </v-card-text>
                    <BlockingBadge :locked="!isClientsNotes" blur>
                        <v-card-text class="px-3 pt-0 mt-0" :class="notes.length == 0 && 'mt-3'">
                            <v-textarea label="Añadir una nota" v-model="newNote" class="body-2" hide-details outlined
                                color="teal accent-4" rows="1" dense auto-grow @click="isNewNoteActive = true"
                                @click:out="isNewNoteActive = false"></v-textarea>
                            <v-btn v-if="isNewNoteActive" class="mt-2 pa-4 rounded-lg" color="teal accent-4"
                                elevation="0" small dark @click="addNote">Guardar</v-btn>
                        </v-card-text>
                    </BlockingBadge>
                </v-card>
                <v-card outlined class="pa-6 mt-3">
                    <v-card-title class="pa-0 font-weight-bold">
                        Historial de reservas
                    </v-card-title>
                    <BlockingBadge :locked="!isClientsHistory" blur>
                        <v-card-text class="pa-3 mt-3" v-for="appointment in client.appointments" :key="appointment.id">
                            <!-- <v-row><span class="body-1 font-weight-bold">Miércoles, 23 de septiembre de 2023</span></v-row> -->
                            <v-row><span class="body-1 font-weight-bold">{{
                                moment(appointment?.start).locale('es').format('dddd, D MMMM YYYY')
                                    }}</span></v-row>
                            <v-row>
                                <v-col cols="5" md="3" class="px-0">
                                    <v-chip class="ma-2 pa-3 font-weight-bold rounded-lg" color="teal accent-4" label
                                        outlined small>
                                        {{ moment(appointment?.start).locale('es').format('HH:mm') }}-{{
                                            moment(appointment?.end).locale('es').format('HH:mm') }}
                                    </v-chip>
                                </v-col>
                                <v-divider vertical class="my-2 px-1 teal accent-4"></v-divider>
                                <v-col cols="6" md="8" class="px-2 d-flex flex-column">
                                    <span class="font-weight-bold">{{ client.fullName }}</span>
                                    <span class="caption">{{ (appointment?.bookings || [])[0]?.createdAt }} vía {{
                                        getBookingOriginText(appointment.booking?.origin) }}</span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text class="pa-3 mt-3" v-if="client.appointments?.length === 0">
                            <v-row>
                                No hay reservas para este cliente
                            </v-row>
                        </v-card-text>
                    </BlockingBadge>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogNote" max-width="500">
            <v-card>
                <v-card-title class="pa-6 font-weight-bold">
                    Editar nota
                    <v-spacer></v-spacer>
                    <v-btn class="pa-4 rounded-lg" color="red" text elevation="0" small
                        @click="deleteNote()">Eliminar</v-btn>
                </v-card-title>
                <v-card-text class="pa-6">
                    <v-textarea label="Nota" v-model="editedNote.content" class="body-2" hide-details outlined
                        color="teal accent-4" rows="3" dense auto-grow></v-textarea>
                </v-card-text>
                <v-card-actions class="pa-6">
                    <v-spacer></v-spacer>
                    <v-btn class="pa-4 rounded-lg" text elevation="0" small @click="dialogNote = false">Cancelar</v-btn>
                    <v-btn class="pa-4 rounded-lg" color="teal accent-4" elevation="0" small dark
                        @click="saveNote()">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import { BOOKING_ORIGINS, FEATURES } from '@/consts';
import { isFeatureAvailable } from '@/utils';

import BlockingBadge from '@/components/business/BlockingBadge.vue'

// TODO: Automate clients details view
export default {
    name: 'DetailsView',
    props: {
        clientId: {
            type: String,
            required: true,
        }
    },
    components: {
        BlockingBadge
    },
    data() {
        return {
            client: {},
            isNewNoteActive: false,
            newNote: '',
            dialogNote: false,
            editedIndex: -1,
            editedNote: {}
        }
    },
    computed: {
        isClientsNotes() {
            return isFeatureAvailable(FEATURES.ClientsNotes)
        },
        isClientsHistory() {
            return isFeatureAvailable(FEATURES.ClientsHistory)
        },
        isClientsBlacklist() {
            return isFeatureAvailable(FEATURES.ClientsBlacklist)
        },
        moment() {
            return moment
        },
        notes() {
            return this.client.notes || []
        },
        isClientBlocked() {
            return this.isClientsBlacklist && this.client.isBlocked
        }
    },
    async created() {
        this.client = await this.fetchClient(this.clientId)
    },
    methods: {
        ...mapActions(['fetchClient', 'deleteClient', 'updateClient', 'updateClientBlockingStatus']),
        getBookingOriginText(origin) {
            return origin === BOOKING_ORIGINS.BOOKING_PAGE ? 'Página de reservas' : 'Calendario de reservas'
        },
        edit() {
            this.$router.push(`/businesses/${localStorage.getItem('businessId')}/clients/form/${this.clientId}`)
        },
        async remove() {
            await this.deleteClient(this.clientId)
            this.$router.push(`/businesses/${localStorage.getItem('businessId')}/clients`)
        },
        async addNote() {
            this.client.notes.push({
                date: new Date(),
                content: this.newNote,
                updated: false
            })
            this.newNote = ''
            this.isNewNoteActive = false

            await this.updateClient(this.client)
            await this.fetchClient(this.clientId)
        },
        editNote(noteIndex) {
            this.editedNote = this.client.notes[noteIndex]
            this.editedIndex = noteIndex
            this.dialogNote = true
        },
        async deleteNote() {
            this.client.notes.splice(this.editedIndex, 1)
            this.dialogNote = false
            this.editedIndex = -1

            await this.updateClient(this.client)
            await this.fetchClient(this.clientId)
        },
        async saveNote() {
            this.client.notes[this.editedIndex].content = this.editedNote.content
            this.client.notes[this.editedIndex].updated = true
            this.dialogNote = false
            this.editedIndex = -1

            await this.updateClient(this.client)
            await this.fetchClient(this.clientId)
        },
        async block() {
            this.client.isBlocked = true
            await this.updateClientBlockingStatus({ clientId: this.client.id, isBlocked: true })
            await this.fetchClient(this.clientId)
        },
        async unblock() {
            this.client.isBlocked = false
            await this.updateClientBlockingStatus({ clientId: this.client.id, isBlocked: false })
            await this.fetchClient(this.clientId)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .note:hover {
    cursor: pointer;
    border-radius: 5px;
    background-color: #f5f5f5;
} */
</style>
